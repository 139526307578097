import Divider from '@material-ui/core/Divider';
// Library components
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import Filter from '../../corners/Filter';
import ManageButtonGroup from '../../corners/ManageButtonGroup';
import SearchField from '../../corners/SearchField';
import Table from '../../corners/Table/Table';
import Button from '../../furnitures/Button';
import Drawer from '../../furnitures/Drawer';
import Toggle from '../../furnitures/Drawer/Toggle';
import Pagination from '../../furnitures/Pagination';
// Custom components
import TableSkeleton from '../../furnitures/SkeletonLoading/TableSkeleton';

const useStyles = makeStyles((theme) => ({
  searchContainer: (props) => ({
    marginBottom: props?.moreOptions ? '15px' : '24px',
    '& > div.search-area-item ~ div.search-area-item': {
      marginRight: 'auto',
    },
    '& .search-area-item + .search-area-item': {
      marginLeft: theme.spacing(2),
    },
  }),
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  actionBtnsContainer: {
    marginBottom: '12px',
    minHeight: '32px',
  },
  mrAuto: {
    marginRight: 'auto',
  },
  moreOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  flipVertically: {
    transform: 'rotate(180deg)',
  },
}));

const ManageTable = ({
  searchInput,
  actionBtnsShow,
  actionBtnsDisable,
  actionBtns,
  tableLoading,
  tableConfig,
  pagination,
  moreOptions,
  component,
}) => {
  const classes = useStyles({ moreOptions });
  const overallLoading = tableLoading;

  const { MoreOptionsDrawer = null, BasicFilter = null, AdvanceFilter = null } = component;

  const showFilter = BasicFilter || AdvanceFilter;
  const enableSearchContainer = searchInput || moreOptions || showFilter;

  return (
    <>
      {/* Search Field */}
      {enableSearchContainer ? (
        <Toggle open={moreOptions?.isOpen}>
          {(toggleState, setToggleState) => (
            <>
              <Grid container className={classes.searchContainer}>
                <Grid item className="search-area-item">
                  {searchInput && (
                    <SearchField
                      {...searchInput}
                      disabled={overallLoading || searchInput?.disabled}
                      onChange={searchInput?.onChange}
                      placeholder={searchInput?.placeholder}
                      icon={searchInput?.icon}
                    />
                  )}
                </Grid>
                <Grid item className={clsx(classes.filterContainer, 'search-area-item')}>
                  {showFilter && (
                    <Filter
                      component={{
                        ...(BasicFilter ? { BasicFilter } : {}),
                        ...(AdvanceFilter ? { AdvanceFilter } : {}),
                      }}
                    />
                  )}
                </Grid>
                <Grid className={classes.moreOptionsContainer}>
                  {moreOptions && (
                    <Button
                      variant={moreOptions?.btnVariant || 'default'}
                      color={moreOptions?.btnColor || 'primary'}
                      size="small"
                      endIcon={
                        <KeyboardArrowDownTwoToneIcon className={clsx({ [classes.flipVertically]: toggleState })} />
                      }
                      onClick={() => setToggleState(!toggleState)}
                    >
                      <Trans>More options</Trans>
                    </Button>
                  )}
                </Grid>
              </Grid>
              {MoreOptionsDrawer && (
                <>
                  <Divider className={classes.divider} />
                  <Drawer open={toggleState}>
                    <MoreOptionsDrawer />
                    <Divider className={classes.divider} />
                  </Drawer>
                </>
              )}
            </>
          )}
        </Toggle>
      ) : null}
      {/* Action buttons & Pagination */}
      {(!!actionBtns || !!pagination) && (
        <Grid container className={classes.actionBtnsContainer} alignItems="center">
          <Grid item className={classes.mrAuto}>
            {actionBtnsShow && !!actionBtns ? (
              <ManageButtonGroup btnGroup={actionBtns} disabledAll={actionBtnsDisable || overallLoading} />
            ) : null}
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              {tableLoading || !!pagination === false ? null : <Pagination {...pagination} />}
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Table */}
      <Grid container>
        {tableLoading ? (
          <TableSkeleton />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Table {...tableConfig} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

ManageTable.defaultProps = {
  searchInput: {
    placeholder: <Trans>Search</Trans>,
    onChange: null,
    icon: <SearchIcon />,
  },
  pagination: {}, // or null to hide pagination
  actionBtnsShow: true,
  actionBtns: [
    {
      color: 'primaryBlack',
      label: <Trans>Bulk Edit</Trans>,
      onClick: null,
      disabled: false,
    },
    {
      color: 'error',
      label: <Trans>Deactivate</Trans>,
      onClick: null,
      disabled: false,
    },
    {
      label: <Trans>Download</Trans>,
      onClick: null,
      disabled: false,
    },
  ],
  component: {},
};

ManageTable.propTypes = {
  searchInput: PropTypes.object,
  actionBtnsShow: PropTypes.bool,
  actionBtns: PropTypes.array,
  pagination: PropTypes.object,
  tableConfig: PropTypes.object,
  tableLoading: PropTypes.bool,
  moreOptions: PropTypes.shape({
    btnText: PropTypes.string,
    btnVariant: PropTypes.string,
    btnColor: PropTypes.string,
  }),
  component: PropTypes.shape({
    MoreOptionsDrawer: PropTypes.node,
    BasicFilter: PropTypes.node,
    AdvanceFilter: PropTypes.node,
  }),
};

export default ManageTable;
