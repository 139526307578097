import { Leg, LegStep } from '@yojee/ui/new-order-booking/types';

import { Location, Order, StepProperty } from '../../../../../../../../types/orders';

type OriginDestination = {
  origin: LegStep | undefined;
  destination: LegStep | undefined;
};

export const getOriginDestinationFromLegSteps = (legs: Leg[]): OriginDestination => {
  const validLegs = legs?.filter((leg) => leg?.every((step) => step !== undefined)) || [];
  const legSteps = validLegs.flat();
  const origin =
    legSteps.find((step) => !step.is_empty && step.type === 'pickup') ||
    legSteps.find((step) => step.type === 'pickup');

  legSteps.reverse();
  const destination =
    legSteps.find((step) => !step.is_empty && step.type === 'dropoff') ||
    legSteps.find((step) => step.type === 'dropoff');

  return {
    origin,
    destination,
  };
};

const isSameLocation = (location: Location | undefined, anotherLocation: Location | undefined) =>
  location?.lat === anotherLocation?.lat && location?.lng === anotherLocation?.lng;

export const getAreOriginsDestinationsMatched = (
  legs: Leg[],
  upstreamMetadata: Order['upstream_metadata']
): boolean => {
  if (upstreamMetadata == null) return true;

  const { origin: uspOrigin, destination: uspDestination } = upstreamMetadata;
  const { origin, destination } = getOriginDestinationFromLegSteps(legs);

  return isSameLocation(origin?.location, uspOrigin) && isSameLocation(destination?.location, uspDestination);
};

export const getStepProperty = (step: LegStep, origin?: LegStep, destination?: LegStep): StepProperty => {
  if (origin && origin.uniqueID === step.uniqueID) {
    return 'origin';
  }

  if (destination && destination.uniqueID === step.uniqueID) {
    return 'destination';
  }

  return null;
};
