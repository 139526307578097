import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class OnboardingApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  createMultipleUsers = (users) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/dispatchers/create_multi'), users)
      .catch((error) => this.handleError(error));
  };

  createMultipleDrivers = (drivers) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/workers/create_multi'), drivers)
      .catch((error) => this.handleError(error));
  };
  createMultipleSenders = (senders) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/senders/create_multi'), senders)
      .catch((error) => this.handleError(error));
  };
}

const onboardingApi = new OnboardingApi({ authService });
export default onboardingApi;
