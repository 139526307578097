import { GetReducerState, RouterReducer } from '@yojee/types';
import { AddressAutoCompleteReducer } from '@yojee/ui/address-autocomplete/reducers/reducer';
import addressPickerReducer from '@yojee/ui/address-picker/reducers/reducer';
import billingJob from '@yojee/ui/billing-job/reducer';
import bulk from '@yojee/ui/BulkUpdate/redux/bulkReducer';
import { DocumentPortalReducer } from '@yojee/ui/documentPortal/reducers/reducer';
import { featuresReducer } from '@yojee/ui/feature-management/reducers/reducer';
import itemsReducer from '@yojee/ui/hierarchy/reducers/reducer';
import { InvitePartnerDialogReducer } from '@yojee/ui/invitePartnerDialog/reducers/reducer';
import invoices from '@yojee/ui/invoices/reducer';
import map from '@yojee/ui/map/reducers/reducer';
import { InvoicesReducer as OrderInvoicesReducer } from '@yojee/ui/new-order-booking/reducers/invoices/reducers';
import { OrderBookingReducer } from '@yojee/ui/new-order-booking/reducers/reducer';
import notificationsReducer from '@yojee/ui/notifications/reducers/reducer';
import { UIAuthReducer } from '@yojee/ui/onboarding/reducers/auth';
import { UIUserManagementReducer } from '@yojee/ui/onboarding/reducers/userManagement';
import { OptimisationReducer } from '@yojee/ui/optimise';
import { PartnerManagementReducer } from '@yojee/ui/partner/reducers/reducer';
import { PartnerHistoryDialogReducer } from '@yojee/ui/partner-history-dialog/reducers/reducer';
import partnerWorker from '@yojee/ui/partnerWorker/reducer';
import paymentReducer from '@yojee/ui/pay/reducers/reducer';
import PlanTimelineReducer from '@yojee/ui/plan-timeline/reducers/reducer';
import RoutePlannerReducer from '@yojee/ui/route-planner/reducers/reducer';
import { RoutePlanningTimelineReducer } from '@yojee/ui/route-planning-timeline';
import { ServiceTypeMappingDialogReducer } from '@yojee/ui/service-type-mapping-dialog/reducers/reducer';

import addressBook from './addressBook';
import addressBookAutoComplete from './addressBookAutoComplete';
import addressTagAutoComplete from './addressTagAutoComplete';
import assignment from './assign';
import bannerFeatures from './bannerFeatures';
import batch from './batch';
import broadcastTaskDialog from './broadcastTaskDialog';
import chat from './chat';
import company from './company';
import completeTask from './completeTask';
import driverAutoComplete from './driverAutoComplete';
import driverTagAutoComplete from './driverTagAutoComplete';
import idAutoComplete from './IdAutoComplete';
import itemsTable from './itemsTable';
import itemTypes from './itemTypes';
import main from './main';
import masterFilter from './masterFilter';
import masterSearchAutocomplete from './masterFilterSearchAutoComplete';
import network from './network';
import notifier from './notifier';
import onboarding from './onboarding';
import orders from './orders';
import pages from './pages';
import partnerAutoComplete from './partnerAutoComplete';
import partnerList from './partnerList';
import partnerSignup from './partnerSignUp';
import planner from './planner';
import reportTask from './reportTask';
import rightViewSelection from './rightViewSelection';
import scanner from './scanner';
import senderAutoComplete from './senderAutoComplete';
import sequence from './sequence';
import serviceTypes from './serviceTypes';
import stopsList from './stopsList';
import taskFilter from './taskFilter';
import tasks from './tasks';
import team from './team';
import transferTasks from './transferTasks';
import withdrawTasks from './withdrawTasks';
import worker from './worker';

const reducers = (routerReducer: RouterReducer) => ({
  router: routerReducer,
  planner,
  auth: UIAuthReducer,
  users: UIUserManagementReducer,
  map,
  optimisation: OptimisationReducer,
  assignment,
  sequence,
  team,
  main,
  worker,
  tasks,
  batch,
  scanner,
  taskFilter,
  company,
  onboarding,
  completeTask,
  reportTask,
  chat,
  notifier,
  proForma: invoices,
  billingJob,
  network,
  orders,
  addressBook,
  pages,
  stopsList,
  itemsTable,
  serviceTypes,
  masterFilter,
  addressBookAutoComplete,
  senderAutoComplete,
  itemTypes,
  driverAutoComplete,
  partnerAutoComplete,
  addressTagAutoComplete,
  driverTagAutoComplete,
  masterSearchAutocomplete,
  idAutoComplete,
  broadcastTaskDialog,
  rightViewSelection,
  partnerList,
  routePlanner: RoutePlannerReducer,
  routePlanningTimeline: RoutePlanningTimelineReducer,
  transferTasks,
  withdrawTasks,
  bannerFeatures,
  partnerSignup,
  planTimeline: PlanTimelineReducer,
  addressPicker: addressPickerReducer,
  notification: notificationsReducer,
  payment: paymentReducer,
  items: itemsReducer,
  features: featuresReducer,
  bulk,
  partnerWorker,
  ...PartnerManagementReducer,
  ...InvitePartnerDialogReducer,
  ...OrderBookingReducer,
  ...InvitePartnerDialogReducer,
  ...AddressAutoCompleteReducer,
  ...DocumentPortalReducer,
  ...OrderInvoicesReducer,
  ...ServiceTypeMappingDialogReducer,
  ...PartnerHistoryDialogReducer,
});

type Reducers = ReturnType<typeof reducers>;

export type ExploreState = {
  [k in keyof Reducers]: GetReducerState<Reducers[k]>;
};

export default reducers;
