import React, { useMemo } from 'react';

import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import Checkbox from '@yojee/ui/base/furnitures/Checkbox';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';

import {
  CONST_VARIABLE,
  MUST_HAVE_READ_PERMISSION_LIST,
  PERMISSION_LIST,
  READ_PERMISSION_LIST,
  WRITE_PERMISSION_LIST,
} from './constants';

function createData(name, checkBoxRead, checkBoxEdit) {
  return { name, checkBoxRead, checkBoxEdit };
}

const useRow = ({ data, permissions, setPermissions, setErrorHelper }) => {
  const reportsEnabled = useIsFeatureEnabled(FEATURES_MAP.ENABLE_REPORTS);
  const partnersEnabled = useIsFeatureEnabled(FEATURES_MAP.ENABLE_PARTNERS);

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  // The data of must have read permission might be false or true but we will set it to true
  // when clicking the save button so doesn't need to check it in all read permission
  const isCheckAllReadPermission = READ_PERMISSION_LIST.filter(
    (permission) => MUST_HAVE_READ_PERMISSION_LIST.indexOf(permission) === -1
  ).every((permission) => permissions.indexOf(permission) > -1);
  const isCheckAllWritePermission = WRITE_PERMISSION_LIST.every((permission) => permissions.indexOf(permission) > -1);
  const isAdmin = data?.name?.toLowerCase() === 'admin';

  const setSingleReadPermission = (readOnlyPermission) => {
    if (permissions.includes(readOnlyPermission)) {
      setPermissions(permissions.filter((permission) => permission !== readOnlyPermission));
    } else {
      setPermissions([...permissions, readOnlyPermission]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setSingleWritePermission = (readOnlyPermission, writeOnlyPermission) => {
    if (permissions.includes(writeOnlyPermission)) {
      setPermissions(
        permissions.filter((permission) => permission !== writeOnlyPermission && permission !== readOnlyPermission)
      );
    } else {
      setPermissions([...permissions, readOnlyPermission, writeOnlyPermission]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setAllReadPermission = () => {
    if (isCheckAllReadPermission) {
      setPermissions(
        permissions.filter((permission) => {
          const permissionSplit = permission.split('.');
          const haveWritePermission = permissions.indexOf(`${permissionSplit[0]}.${permissionSplit[1]}.write`) > -1;
          return (
            WRITE_PERMISSION_LIST.indexOf(permission) > -1 ||
            (READ_PERMISSION_LIST.indexOf(permission) > -1 && haveWritePermission)
          );
        })
      );
    } else {
      setPermissions([...new Set(permissions.concat(READ_PERMISSION_LIST))]);
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const setAllWritePermission = () => {
    if (isCheckAllWritePermission) {
      setPermissions([]);
    } else {
      setPermissions(READ_PERMISSION_LIST.concat(WRITE_PERMISSION_LIST));
    }
    setErrorHelper(CONST_VARIABLE.PERMISSION);
  };

  const rows = useMemo(() => {
    const rowsData = [];
    const featureFlags = {
      reports: reportsEnabled,
      partners: partnersEnabled,
    };

    Object.keys(PERMISSION_LIST)
      .filter((permissionName) => !(permissionName in featureFlags) || featureFlags[permissionName])
      .forEach((permissionName) => {
        const readOnlyPermission = 'dispatcher.' + permissionName + '.read';
        const writeOnlyPermission = 'dispatcher.' + permissionName + '.write';
        const isMustHaveReadPermission = MUST_HAVE_READ_PERMISSION_LIST.includes(readOnlyPermission);

        rowsData.push(
          createData(
            capitalizeFirstLetter(permissionName),
            <Checkbox
              color="primary"
              checked={permissions.includes(readOnlyPermission) || isMustHaveReadPermission}
              onChange={() => setSingleReadPermission(readOnlyPermission)}
              disabled={isAdmin || permissions.includes(writeOnlyPermission) || isMustHaveReadPermission}
            />,
            <Checkbox
              color="primary"
              checked={permissions.includes(writeOnlyPermission)}
              onChange={() => setSingleWritePermission(readOnlyPermission, writeOnlyPermission)}
              disabled={isAdmin}
            />
          )
        );
      });

    rowsData.unshift(
      createData(
        'All',
        <Checkbox
          color="primary"
          checked={isCheckAllReadPermission}
          onChange={setAllReadPermission}
          disabled={isAdmin || isCheckAllWritePermission}
        />,
        <Checkbox
          color="primary"
          checked={isCheckAllWritePermission}
          onChange={setAllWritePermission}
          disabled={isAdmin}
        />
      )
    );

    return rowsData;
  }, [permissions, data]);

  return { rows };
};
export default useRow;
