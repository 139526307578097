/***
 * Extension of planner saga to assist with loading tasks + filter + search
 * ***/

import { chunk as chunkLd } from 'lodash-es';
import { call, put, select, take } from 'redux-saga/effects';

import { regionService } from '@yojee/api/regionsService';
import { serviceTimeService } from '@yojee/api/serviceTimeService';
import { tasksSearchService } from '@yojee/api/tasksSearchService';
import * as umbrellaApi from '@yojee/api/umbrellaApi';
import AuthSelectors from '@yojee/auth/store/selectors';
import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { getShouldFetchItemRelatedLegs } from '@yojee/helpers/MasterFilterHelper';
import { getFiltersByOrderItemIds, mapFilters, transformResults } from '@yojee/helpers/SearchHelper';
import {
  getCurrentTaskState,
  getMasterFilter,
  getSearchTask,
  getShowList,
  getTaskData2,
  getZonesData,
  isPairTaskIncluded,
} from '@yojee/helpers/TasksHelper';

/**
 * USING ELASTISEARCH
 * HIGHLY EXPERIMENTAL
 */
export function* searchTasks(params = {}) {
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug, id: companyId },
        },
      },
    } = yield select(AuthSelectors.getData);
    const masterState = yield select(getMasterFilter);
    const taskState = params.taskState ? params.taskState : yield select(getCurrentTaskState);
    const searchState = yield select(getSearchTask);
    let regions = yield select(getZonesData);
    const currentMasterFilter = masterState.filter;
    const relatedLegs = params.relatedLegs;
    const includeTransferProperties = [AVAILABLE_FILTERS.TRANSFERRED, AVAILABLE_FILTERS.ALL].includes(taskState);
    const pairTaskIncluded = isPairTaskIncluded(currentMasterFilter.taskType);

    if (currentMasterFilter.serviceTypeIds?.length > 0) {
      yield call(waitFor, (state) => !state?.serviceTypes?.inProgress);
    }
    const serviceTypes = yield select((state) => state.serviceTypes?.data);

    if (
      (taskState === AVAILABLE_FILTERS.REPORTED || currentMasterFilter.status.includes('reported')) &&
      currentMasterFilter.reportInfoWithReason.length > 0
    ) {
      return yield searchTasksReportedWithReason({
        masterState,
        searchState,
        regions,
        params: { ...params, onlySameLeg: pairTaskIncluded },
        taskState,
        serviceTypes,
      });
    }

    if (currentMasterFilter.region_ids?.length > 0 && (regions?.length ?? 0) < 1) {
      const { data: regionsData } = yield call(regionService.fetchRegions);
      regions = regionsData?.data || [];
    }

    const filterBody = mapFilters({ companyId, masterState, taskState, searchState, regions, params, serviceTypes });
    const { data } = yield call(tasksSearchService.search, filterBody, {
      token,
      partnerJwt,
      slug,
      relatedLegs,
      onlySameLeg: pairTaskIncluded,
      includeTransferProperties,
    });
    return transformResults(data);
  } catch (error) {
    console.error(error);
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
    // Return empty result
    return transformResults();
  }
}

export function* searchTasksReportedWithReason({ masterState, searchState, regions, params, taskState }) {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id: companyId },
        },
      },
    } = yield select(AuthSelectors.getData);
    const serviceTypes = yield select((state) => state.serviceTypes?.data);
    const currentMasterFilter = masterState.filter;
    const firstFilterBody = mapFilters({
      companyId,
      masterState: { ...masterState, filter: { ...currentMasterFilter, status: ['reported'] } },
      taskState: 'ALL',
      searchState,
      regions,
      params: { ...params, relatedLegs: false },
      serviceTypes,
    });
    const getFailedTasksByReasons = yield call(tasksSearchService.search, firstFilterBody, {
      relatedLegs: false,
      onlySameLeg: false,
    });
    const failedTaskByReasons = transformResults(getFailedTasksByReasons.data);
    if (currentMasterFilter.status.length === 1 && failedTaskByReasons.data && failedTaskByReasons.data.length < 1) {
      return failedTaskByReasons;
    }
    const orderItemIdsOfFailedTaskByReason = failedTaskByReasons.data?.map((d) => d.order_item.id) || [];
    const modifiedMasterState = {
      ...masterState,
      filter: {
        ...currentMasterFilter,
        reportInfoWithReason: [],
        status:
          failedTaskByReasons.data.length > 0
            ? currentMasterFilter.status
            : currentMasterFilter.status.filter((s) => s !== 'reported'),
      },
    };
    const tasksAndReportedWithoutReasons = mapFilters({
      companyId,
      masterState: modifiedMasterState,
      taskState,
      searchState: {
        ...searchState,
        order_item_ids: currentMasterFilter.status.length === 1 ? orderItemIdsOfFailedTaskByReason : [],
      },
      regions,
      params,
      serviceTypes,
    });
    const { data } = yield call(tasksSearchService.search, tasksAndReportedWithoutReasons, {
      relatedLegs: params.relatedLegs,
      onlySameLeg: params.onlySameLeg,
    });

    const allTaskIncludeAllReported = transformResults(data);
    const postCount = allTaskIncludeAllReported.data.length;

    allTaskIncludeAllReported.data = allTaskIncludeAllReported.data.filter((t) => {
      return orderItemIdsOfFailedTaskByReason.includes(t.order_item.id);
    });
    allTaskIncludeAllReported.count =
      allTaskIncludeAllReported.count - (postCount - allTaskIncludeAllReported.data.length);
    allTaskIncludeAllReported.count =
      allTaskIncludeAllReported.count > 0 ? allTaskIncludeAllReported.count : allTaskIncludeAllReported.data.length;
    return allTaskIncludeAllReported;
  } catch (error) {
    console.error(error);
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
    // Return empty result
    return transformResults();
  }
}

export function* searchTasksByOrderItemIds(orderItemIds, size, onlyCreatedOrCompleted = false) {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id: company_id },
        },
      },
    } = yield select(AuthSelectors.getData);
    const filterBody = getFiltersByOrderItemIds(company_id, orderItemIds, size, onlyCreatedOrCompleted);
    const { data } = yield call(tasksSearchService.search, filterBody, {});
    return transformResults(data);
  } catch (error) {
    console.error(error);
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
    // Return empty result
    return transformResults();
  }
}

function* waitFor(selector) {
  const value = yield select(selector);
  if (value) return;

  while (true) {
    yield take('*');
    const value = yield select(selector);
    if (value) return;
  }
}

export function* initTasks({ changeZoom = true, onSuccess = () => {} }) {
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'FETCH_TASKS_START' });
    let newTaskList;

    // New list view using ES search
    yield call(waitFor, (state) => getValue(state, 'auth.dispatcher_info.data.id') !== null || partnerJwt);
    const masterState = yield select(getMasterFilter);
    const currentMasterFilter = masterState.filter;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (currentMasterFilter.idKey !== 'transferred_order_number') {
      const showList = yield select(getShowList);
      const relatedLegs = yield select(getShouldFetchItemRelatedLegs);
      const count = showList ? 100 : 500;
      newTaskList = yield searchTasks({ token, partnerJwt, slug, relatedLegs, size: count });

      const anyMoretasks = yield searchTasks({ from: count, relatedLegs, size: 1 });
      yield put({ type: 'CHECK_FOR_MORE_TASKS', tasks: anyMoretasks });
      if (changeZoom) {
        yield put({ type: 'ZOOM_SPECIFIC', data: newTaskList?.data });
      }
    } else {
      const params = {
        page: 1,
        page_size: 300,
        order_number: currentMasterFilter.idValue,
        include_transfer_properties: true,
        query_version: 'v3',
        task_states: ['completed', 'created', 'pending_transfer', 'transferred'],
      };
      const response = yield call(umbrellaApi.fetchTransferedTasks, params, { token, partnerJwt, slug });
      if (response && response.data && response.data.data) {
        newTaskList = {
          data: response.data.data.map((t, i) => ({ ...t, task: t, order_step: t, id: `transferred_task_${i}` })),
          count: response.data.data.length,
          countType: 'eq',
        };
      } else {
        newTaskList = {};
      }
    }
    yield put({ type: 'FETCH_TASKS_SUCCEEDED', tasks: newTaskList });
    // We need to wait for fetching ETA done before setting loadingState to false via FETCH_TASKS_COMPLETE
    // EXPLANATION: Items List View in Explore is using react-virtualized and the data to be rendered are both tasks and ETA from 2 different end points
    // If we don't wait for ETA, the List View is rendered without ETA. Then ETA data comes and causes overflow
    // In that case, we need to call tableRef.current.forceUpdateGrid() so that the row heights are recalculated
    // Then, overflow is fixed but the scroll view jumps due to the recalculation
    // Therefore, the best solution is to WAIT FOR ALL DATA TO BE FETCHED before rendering the List View using react-virtualized
    yield take('FETCH_ETA_DONE');
    yield put({ type: 'FETCH_TASKS_COMPLETE' });
    onSuccess();
  } catch (err) {
    console.log(err);
    yield put({ type: 'FETCH_TASKS_ERROR', err });
    yield put({ type: 'FETCH_TASKS_COMPLETE' });
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}

export function* loadMoreTask({ resolve }) {
  yield loadMoreTaskESSearch({ resolve });
}

export function* loadMoreTaskESSearch({ resolve }) {
  const normalizeTasks = yield select(getTaskData2);

  const data = normalizeTasks.data || {};
  let from = Object.keys(data).length;

  const showList = yield select(getShowList);
  const relatedLegs = yield select(getShouldFetchItemRelatedLegs);

  if (relatedLegs) {
    from = new Set(Object.keys(data).map((k) => data[k].order_item_id)).size;
  }
  const count = showList ? 100 : 500;
  const tasks = yield searchTasks({ from, relatedLegs, size: count });
  const next = from + count;
  const anyMoretasks = yield searchTasks({ from: next, relatedLegs, size: 1 });

  yield put({ type: 'FETCH_ETA', tasks });
  // Read EXPLANATION in initTasks
  yield take('FETCH_ETA_DONE');
  yield put({ type: 'LOAD_MORE_TASK_SUCCESS', tasks });
  yield put({ type: 'CHECK_FOR_MORE_TASKS', tasks: anyMoretasks });
  yield put({ type: 'FETCH_TASKS_COMPLETE', resolve });
  if (!showList) {
    yield put({ type: 'ZOOM_SPECIFIC', data: tasks });
  }
  // yield put({ type: "BUID_WORKER_SEQUENCE_DATA" })
}

export function* fetchServiceTimes(tasks) {
  if (!tasks) {
    return {
      tasks: [],
      serviceTimes: [],
    };
  }

  const chunks = chunkLd(
    tasks.map((t) => t.id),
    1000
  );
  let serviceTimes = {};
  for (let i = 0; i < chunks.length; i++) {
    const { data: fetchedServiceTimes } = yield call(serviceTimeService.calculateForTasks, { tasksIds: chunks[i] });
    serviceTimes = { ...serviceTimes, ...fetchedServiceTimes };
  }

  const mappedServiceTimes = {};
  Object.values(serviceTimes).forEach((t) => {
    if (t?.condition?.id) {
      if (!mappedServiceTimes[t.condition.id]) {
        mappedServiceTimes[t.condition.id] = t.condition;
      }
    }
  });

  return {
    tasks: tasks.map((t) => ({
      ...t,
      service_time: serviceTimes[t.id]?.value,
      service_time_condition_id: serviceTimes[t.id]?.condition?.id,
      item_service_time_value: serviceTimes[t.id]?.item_service_time_value,
      per_item_service_time_value: serviceTimes[t.id]?.condition?.multiply_by_quantity
        ? serviceTimes[t.id]?.item_service_time_value / t.task.quantity
        : serviceTimes[t.id]?.item_service_time_value,
      location_service_time_value: serviceTimes[t.id]?.location_service_time_value,
      service_time_multiply_by_quantity: serviceTimes[t.id]?.condition?.multiply_by_quantity,
    })),
    serviceTimes: Object.values(mappedServiceTimes),
  };
}
