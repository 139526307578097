import _ from 'lodash-es';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as Api from '@yojee/api/umbrellaApi';
import AuthSelectors from '@yojee/auth/store/selectors';

export const getTeamList = (state) => state.team && state.team.data;
export const getPlannerAllDriver = (state) => _.get(state, 'planner.workerData.data', []);
export const getSelectedTeam = (state) => state.team && state.team.selectedTeams;
export const getSelectedTasks = (state) => state.planner && state.planner.selectedTasks;
export default function* sagas() {
  yield takeLatest('FETCH_ALL_TEAM_REQUEST', fetchAllTeams);
  yield takeLatest('SELECT_TEAM', selectTeam);
  yield takeLatest('DESELECT_TEAM', deSelectTeam);
  yield takeLatest('SELECT_WORKER', selectWorkerInTeam);
  yield takeLatest('DESELECT_WORKER', deSelectWorkerInTeam);
  yield takeLatest('SELECT_STOP', updateSelectedWorkers);
}

function* fetchAllTeams() {
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const { data } = yield call(Api.fetchAllTeam, { token, slug, partnerJwt });
    yield put({ type: 'FETCH_ALL_TEAM_REQUEST_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'FETCH_ALL_TEAM_REQUEST_ERROR', error });
  }
}

function* selectWorkerInTeam({ teamId, worker }) {
  const selectedTeam = yield select(getSelectedTeam);
  // check team exist in selectedTeam or not
  if (selectedTeam && selectedTeam.length > 0) {
    const teamsByFilterId = selectedTeam.filter((t) => t.teamId === teamId);
    if (teamsByFilterId && teamsByFilterId.length > 0) {
      const teamById = teamsByFilterId[0];
      teamById.drivers = Array.from(new Set(teamById.drivers.concat([worker])));
      const newSelectedTeams = selectedTeam.filter((t) => t.teamId !== teamId).concat([teamById]);
      yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
      yield put({
        type: 'UPDATE_SELECTED_WORKER',
        selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
      });
      return;
    }
  }
  const newSelectedTeams = selectedTeam.concat([{ teamId, drivers: [worker] }]);
  yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
  yield put({
    type: 'UPDATE_SELECTED_WORKER',
    selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
  });
}

function* deSelectWorkerInTeam({ teamId, worker }) {
  const selectedTeam = yield select(getSelectedTeam);
  // check team exist in selectedTeam or not
  if (selectedTeam && selectedTeam.length > 0) {
    const teamsByFilterId = selectedTeam.filter((t) => t.teamId === teamId);
    if (teamsByFilterId && teamsByFilterId.length > 0) {
      const teamById = teamsByFilterId[0];
      teamById.drivers = Array.from(new Set(teamById.drivers.filter((d) => d !== worker)));
      const newSelectedTeams = selectedTeam
        .filter((t) => t.teamId !== teamId)
        .concat([teamById])
        .filter((t) => t.drivers.length > 0);
      yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
      yield put({
        type: 'UPDATE_SELECTED_WORKER',
        selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
      });
      return;
    }
  }
  const newSelectedTeams = selectedTeam.concat([{ teamId, drivers: [worker] }]);
  yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
  yield put({
    type: 'UPDATE_SELECTED_WORKER',
    selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
  });
}

function* selectTeam({ teamId }) {
  const selectedTeam = yield select(getSelectedTeam);
  const teamList = yield select(getTeamList);
  const driverListByTeam = teamList.find((t) => t.id === teamId);
  let drivers = [];
  if (teamId === -1) {
    const allDriver = yield select(getPlannerAllDriver);
    drivers = allDriver.map((d) => d.id);
  }
  if (driverListByTeam && driverListByTeam.workers && driverListByTeam.workers.length > 0) {
    drivers = driverListByTeam.workers.map((w) => w.id);
  }
  const newSelectedTeams = selectedTeam.filter((t) => t.teamId !== teamId).concat([{ teamId, drivers }]);
  yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
  yield put({
    type: 'UPDATE_SELECTED_WORKER',
    selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
  });
}

function* deSelectTeam({ teamId }) {
  const selectedTeam = yield select(getSelectedTeam);
  const newSelectedTeams = selectedTeam.filter((t) => t.teamId !== teamId && t.drivers.length > 0).concat([]);
  yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams });
  yield put({
    type: 'UPDATE_SELECTED_WORKER',
    selectedWorkers: Array.from(new Set(newSelectedTeams.map((t) => t.drivers).flatMap((x) => x))),
  });
}

function* updateSelectedWorkers() {
  const selectedTasks = yield select(getSelectedTasks);
  if (selectedTasks.length === 0) {
    yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams: [] });
  }
}
