import { call, put, select, takeLatest } from 'redux-saga/effects';

import { tasksSearchService } from '@yojee/api/tasksSearchService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { getSearchQuery, transformSuggestionSearch } from '@yojee/helpers/search-helper';

export default function* sagas() {
  yield takeLatest('SEARCH_ID', searchId);
}

function* searchId({ searchKey }) {
  let searchFilters;
  try {
    const {
      dispatcher_info: {
        data: {
          company: { id },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'SEARCH_ID_START', searchKey: searchKey });
    searchFilters = getSearchQuery(id, searchKey, 2500, true);
    const { data } = yield call(tasksSearchService.search, searchFilters, {});
    yield put({ type: 'SEARCH_ID_SUCCESS', options: transformSuggestionSearch(data, searchKey.split(';')) });
  } catch (error) {
    yield put({ type: 'SEARCH_DRIVER_FAILED', error });
    console.error(error, { extra: searchFilters });
  }
}
