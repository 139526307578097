import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  labelStyles: {
    display: 'block',
    lineHeight: '12px',
    wordWrap: 'break-word',
    color: theme.palette?.black?.['B60'],
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  labelRequired: ({ error }) => ({
    color: error ? theme.palette.error.main : 'inherit',
  }),
}));

const InputLabel = ({ required, label, className = '', ...restProps }) => {
  const classes = useStyles({ restProps });
  return (
    <span className={clsx(classes.labelStyles, className)} {...restProps}>
      {label}
      <span className={classes.labelRequired}>{required ? ' *' : ''}</span>
    </span>
  );
};

InputLabel.defaultProps = {
  label: '\u00A0',
  require: false,
  error: false,
};

InputLabel.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
};

export default InputLabel;
