import { call, put } from 'redux-saga/effects';

export const BATCH_UPLOAD_ITEM_TYPES = {
  WORKER: 'worker',
  WORKER_SCHEDULE: 'worker_schedule',
  VEHICLE: 'vehicle',
  VEHICLE_SCHEDULE: 'vehicle_schedule',
  DISPATCHER: 'dispatcher',
  ADDRESS: 'address',
  EXCEPTIONS: 'task_exception_reason',
  TAGS: 'tags',
  SENDER: 'sender',
  SENDER_ORGANISATION: 'sender_organisation',
  SENDER_ORGANISATION_USER: 'sender_organisation_user',
  CHARGE_CODE: 'charge_code',
  ENTERPRISE: 'enterprise',
  REASON_CODE: 'reason_code',
};

export const BATCH_TEMPLATE_TYPES = {
  SINGLE_TASK: { id: 'single_task', text: 'Single Task' },
  SINGLE_LEG: { id: 'point_to_point', text: 'Single Leg' },
  MULTIPLE_LEGS: { id: 'multiple_legs', text: 'Multiple Legs' },
};

function delay(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(true), ms));
}

export function* batchUpload(
  { file, format, type = null },
  { uploadApiMethod, statusCheckApiMethod, events: { started, processing, succeeded, failed } }
) {
  try {
    yield put({ type: started });
    const { data } = yield call(uploadApiMethod, { file, format, type });

    const { batch_id: batchId } = data;
    yield put({ type: processing, id: batchId });

    let status = 'processing';
    let totalProcessed = 0;
    let statusCheckData;
    while (status === 'processing') {
      const { data: statusData } = yield call(statusCheckApiMethod, { batchId });
      status = statusData.status;
      statusCheckData = statusData;
      totalProcessed = statusData.total;
      yield delay(1000);
    }
    if (status === 'completed') {
      yield put({ type: succeeded, total: totalProcessed });
    } else if (status === 'failed') {
      yield put({ type: failed, batchId, errors: statusCheckData.logs ? { errors: statusCheckData.logs } : {} });
    }
  } catch (e) {
    yield put({
      type: failed,
      errors: {
        errors: e.message
          ? Array.isArray(e.message)
            ? e.message
            : [{ serverError: e.message }]
          : [{ serverError: `Please populate all mandatory fields` }],
      },
    });
  }
}

export const batchUploadDefaultState = {
  state: 'not_submitted',
  errors: {},
  file: null,
  batchId: null,
};

export function generateBatchUploadReducerMethods({ prefix }) {
  return {
    [`${prefix}_BATCH_UPLOAD_STARTED`]: (state) => {
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'processing',
        },
      };
    },
    [`${prefix}_BATCH_UPLOAD_PROCESSING`]: (state, { id }) => {
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'processing',
          batchId: id,
        },
      };
    },
    [`${prefix}_BATCH_UPLOAD_SUCCEEDED`]: (state, { total }) => {
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'completed',
          errors: {},
          total: total,
        },
      };
    },
    [`${prefix}_BATCH_UPLOAD_FAILED`]: (state, { errors = {}, batchId = null }) => {
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'failed',
          batchId,
          errors,
        },
      };
    },
    REFRESH_BATCH_UPLOAD: (state) => {
      return {
        ...state,
        batchUpload: {
          ...batchUploadDefaultState,
        },
      };
    },
  };
}
