import i18n from 'i18next';
import Cookies from 'js-cookie';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { userService } from '@yojee/api/userService';
import { clearJwtData, decodeJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { getDispatcherSupportedLanguages, getSystemLanguageForUser, languageStorage } from '@yojee/helpers/languages';
import { CUSTOM_EVENT_NAMES, triggerWindowEvent } from '@yojee/helpers/triggerWindowEvent';
import { LocalStorage } from '@yojee/local-storage/local-storage';

export const getAuthData = (state) => state.auth;

const prefix = process.env.REACT_APP_PREFIX || '';
const ACCESS_SETTINGS_VALUES = {
  HIDE: 'hide',
  SHOW: 'show',
  DISABLE: 'disable',
};

export function* UIAuthSagas() {
  yield takeLatest('UI_AUTH_REQUEST_LOGIN', login);
  yield takeLatest('UI_AUTH_REQUEST_SEND_SSO_LINK', sendSSOLink);
  yield takeLatest('UI_AUTH_REQUEST_DISPATCHER_INFO', getDispatcherInfo);
  yield takeLatest('UI_AUTH_REQUEST_RELATED_COMPANIES', getRelatedCompanies);
  yield takeLatest('UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY', getTokenForRelatedCompany);
  yield takeLatest('UI_AUTH_REQUEST_NEW_PASSWORD', requestNewPassword);
  yield takeLatest('UI_AUTH_EDIT_PASSWORD', editPassword);
  yield takeLatest('UI_AUTH_RESET_NEW_PASSWORD_SETTINGS', resetNewPasswordSettings);
  yield takeLatest('UI_AUTH_LOGOUT', logout);
  yield takeLatest('UI_AUTH_REQUEST_SIGN_UP', signUp);
  yield takeLatest('UI_AUTH_SAVE_JWT_TOKEN', saveJwtToken);
  yield takeLatest('UI_AUTH_REQUEST_SET_USER_LANGUAGE', setUserLanguage);
  yield takeLatest('@@router/LOCATION_CHANGE', handleAuthLocationChange);
  yield takeLatest('@@router/LOCATION_CHANGE', handleManageClose);
}

function handleManageClose({ payload }) {
  if (!(payload?.location?.pathname ?? '').startsWith('/manage') && document.getElementById(`yojeeManage-container`)) {
    window[`unmountyojeeManage`] && window[`unmountyojeeManage`]();
  }
}

function* handleAuthLocationChange(action) {
  const {
    location: { pathname, search },
  } = action.payload;

  //  if user is being redirected to Login, password-related page, or /partner/ page which doesn't require authentication
  //  do not make extra API calls
  if (pathname && ['/login', '/signup/', '/password/'].find((str) => pathname.includes(str))) {
    return;
  }
  const params = new URLSearchParams(search);
  const partnerJwtToken = params && params.get('partner_jwt');
  const trackingJwtToken = params && params.get('tracking_jwt');
  if (trackingJwtToken || partnerJwtToken) {
    yield put({ type: 'RESET_ALL_STORE' });
  }

  if (partnerJwtToken) {
    yield put({ type: 'UPDATE_FILTER_BY_QUERY_STRING' });
    yield put({ type: 'REFRESH_ALL' });
    return;
  }

  // for case : back to previous query
  if (!action.payload.isFirstRendering) {
    if (['explore'].find((str) => pathname.includes(str))) {
      const callbackAction = { type: 'REFRESH', initiator: 'handleAuthLocationChange' };
      yield put({ type: 'UPDATE_FILTER_BY_QUERY_STRING', callbackAction });
    }
  }
}

function* login(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'login' });
    const slug = action.slug || getSlugFromUrl();
    LocalStorage.setItem('slug', slug);
    const {
      data: {
        jwt_tokens,
        dispatcher_companies,
        auth_infos: { dispatcher_infos },
      },
    } = yield call(userService.login, action.payload);
    if (
      !dispatcher_infos ||
      !Object.keys(dispatcher_infos) ||
      (!Object.keys(dispatcher_infos).find((dispatcherSlug) => dispatcherSlug === slug) &&
        action.loginFlow !== 'partnerSignUp')
    ) {
      yield put({
        type: 'UI_AUTH_LOGIN_FAILED',
        error: { message: 'Unauthorized: This account does not belong to this company' },
      });
      return;
    }
    const jwtData = decodeJwtData(jwt_tokens);
    // store jwt tokens data to local
    yield call(saveJwtData, jwtData);

    //  store jwt tokens data and dispatcher_companies to auth redux store
    yield put({ type: 'UI_AUTH_LOGIN_SUCCESSFUL', jwtData, dispatcher_companies });
    triggerWindowEvent(CUSTOM_EVENT_NAMES.login, { jwtData, dispatcher_companies });

    //  call API to fetch dispatcher info
    yield call(getDispatcherInfo, {
      payload: {
        dispatcherCompanies: dispatcher_companies,
        jwtData,
      },
      slug: action.slug,
      redirectUrl: action.redirectUrl,
    });

    if (action.redirectUrl) {
      const domainItems = window.location.host.split('.');
      window.location.href = `${window.location.protocol}//${slug}.${domainItems
        .slice(Math.max(domainItems.length - 3, 0))
        .join('.')}${action.redirectUrl}`;
    } else {
      yield put(push('/explore'));
    }
  } catch (error) {
    yield put({ type: 'UI_AUTH_LOGIN_FAILED', error, preventErrorDisplay: action.payload?.preventErrorDisplay });
  }
}

function* getDispatcherInfo(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'getDispatcherInfo' });

    let jwtData, dispatcherCompanies, partnerJwt;
    const slug = action.slug || getSlugFromUrl();

    if (!action.payload) {
      const authData = yield select(getAuthData);
      jwtData = authData.jwtData;
      dispatcherCompanies = authData.dispatcherCompanies;
      partnerJwt = authData.partnerJwt;
    } else {
      jwtData = action.payload.jwtData;
      dispatcherCompanies = action.payload.dispatcherCompanies;
    }

    if (typeof jwtData === 'string') {
      jwtData = JSON.parse(jwtData);
    }

    // don't save user info on Sign Up page
    if (!action.redirectUrl) {
      // store jwt tokens data to local
      saveJwtData(jwtData);
      //  store jwt tokens data and dispatcher_companies to auth redux store
      yield put({ type: 'UI_AUTH_LOGIN_SUCCESSFUL', jwtData, dispatcher_companies: dispatcherCompanies });
      LocalStorage.setItem('dispatcher_companies', dispatcherCompanies);
      triggerWindowEvent(CUSTOM_EVENT_NAMES.login, { jwtData, dispatcher_companies: dispatcherCompanies });
    }
    //Authorization: partnerJwt
    const { data: dispatcher_info } = yield call(userService.getUserInfo, {
      options: { slug, Authorization: partnerJwt },
    });

    const supportedLanguages = yield select(getDispatcherSupportedLanguages);
    const language = yield getSystemLanguageForUser(dispatcher_info.language, supportedLanguages);

    const synchronizeDispatcherInfo = {
      ...dispatcher_info,
      language,
    };

    if (language) {
      i18n.changeLanguage(language);
      languageStorage.setSavedLanguage(language);
    }

    const { permissions } = synchronizeDispatcherInfo.role;
    const _mappedPermissions = {};
    Object.values(permissions).forEach((p) => {
      p.group_features.forEach((f) => {
        _mappedPermissions[f] = p.role_permission ? p.company_permission : ACCESS_SETTINGS_VALUES.HIDE;
      });
    });
    synchronizeDispatcherInfo.role.permissions = _mappedPermissions;

    // const loginData = { dispatcher_info: { data: synchronizeDispatcherInfo } };

    // don't save user info on Sign Up page
    if (!action.redirectUrl) {
      LocalStorage.setItem('current_user', synchronizeDispatcherInfo);
    }

    yield put({ type: 'UI_AUTH_GET_DISPATCHER_INFO_SUCCESSFUL', dispatcher_info: { data: synchronizeDispatcherInfo } });
    const _domain = window.location.hostname.split('.').slice(-2).join('.');

    if (action.slug) {
      Cookies.set('jwt_data', JSON.stringify(jwtData), { domain: `.${_domain}` });
      Cookies.set('company_slug', slug, { domain: `.${_domain}` });
      Cookies.set('dispatcher_companies', JSON.stringify(dispatcherCompanies), { domain: `.${_domain}` });
    }
  } catch (error) {
    yield put({ type: 'UI_AUTH_LOGIN_FAILED', error });
  }
}

function* getRelatedCompanies() {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'getRelatedCompanies' });
    const { data } = yield call(userService.getRelatedCompanies);
    yield put({ type: 'UI_AUTH_REQUEST_RELATED_COMPANIES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UI_AUTH_REQUEST_RELATED_COMPANIES_FAILED', error });
  }
}

function* getTokenForRelatedCompany({ payload: { slug } }) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'getTokenForRelatedCompany' });
    const { data } = yield call(userService.getTokenForRelatedCompany, { slug });

    const params = yield new URLSearchParams(data.jwt_tokens);

    yield put({
      type: 'UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY_SUCCESSFUL',
    });
    const domainItems = window.location.host.split('.');

    window.location.href = `${window.location.protocol}//${slug}.${domainItems
      .slice(Math.max(domainItems.length - 3, 0))
      .join('.')}/switch-company?${params.toString()}`;
  } catch (error) {
    yield put({ type: 'UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY_FAILED', error });
  }
}

function* setUserLanguage({ payload }) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'changeUserLanguage' });
    languageStorage.setSavedLanguage(payload.language);
    yield call(userService.setUserLanguage, payload);
    window.location.reload();
  } catch (error) {
    // no need to handle
  }
}

function* saveJwtToken({ payload }) {
  try {
    const jwtData = decodeJwtData(payload);
    yield call(saveJwtData, jwtData);
    const slug = getSlugFromUrl();

    yield put({ type: 'UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY_SUCCESSFUL', data: null });
    yield call(getDispatcherInfo, {
      payload: {
        jwtData,
      },
      slug,
      redirectUrl: '/explore',
    });
    window.location.href = '/explore';
  } catch (e) {
    // No need to handle error case
  }
}

function* signUp(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'signUp' });

    const companyName = action.payload.company.toLowerCase().replace(/\s/g, '');
    const arrayComponent = companyName.replace(/[^a-zA-Z0-9-_]/g, '-').split('-');
    const formattedCompanyName = arrayComponent.reduce((current, value, index) => {
      return value ? `${current}${current ? '-' : ''}${value}` : current;
    }, '');

    const { data } = yield call(userService.signUp, {
      company: {
        name: action.payload.company,
        slug: formattedCompanyName,
        country: action.payload.country,
        currency: 'SGD',
        type: action.payload.product === 'blitz' ? 'blitz' : 'empire',
      },
      dispatcher: {
        name: action.payload.name,
        email: action.payload.email,
        password: action.payload.password,
      },
      ...(action.payload.product === 'blitz'
        ? {
            options: {
              create_individual_sender: true,
              create_corp_sender: true,
              create_worker: false,
              create_custom_service_type: false,
              create_example_tasks: false,
            },
          }
        : {}),
    });
    yield call(login, {
      payload: {
        email: action.payload.email,
        password: action.payload.password,
      },
      redirectUrl: `/subscribe/${action.payload.product}`,
      slug: formattedCompanyName,
    });

    yield put({ type: 'UI_AUTH_REQUEST_SIGN_UP_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'UI_AUTH_REQUEST_SIGN_UP_FAILED', message: error.message });
  }
}

function* requestNewPassword(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'requestNewPassword' });
    const { message } = yield call(userService.requestNewPassword, action.payload);
    yield put({ type: 'UI_AUTH_REQUEST_NEW_PASSWORD_SUCCESS', message });
  } catch (error) {
    yield put({ type: 'UI_AUTH_REQUEST_NEW_PASSWORD_FAILED', error });
  }
}

function* editPassword(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'editPassword' });
    const { message } = yield call(userService.editPassword, action.payload);
    yield put({ type: 'UI_AUTH_EDIT_PASSWORD_SUCCESS', message });
  } catch (error) {
    yield put({
      type: 'UI_AUTH_EDIT_PASSWORD_FAILED',
      message: error.message || 'Wrong request.',
    });
  }
}

function* sendSSOLink(action) {
  try {
    yield put({ type: 'UI_AUTH_LOADING', key: 'sendSSOLink' });
    const { success } = yield call(userService.sendSSOLink, { email: action.payload?.email, type: 'dispatcher' });
    if (success) {
      yield put({ type: 'UI_AUTH_SEND_SSO_LINK_SUCCESS', message: 'Login link was sent to your email' });
    } else {
      yield put({
        type: 'UI_AUTH_SEND_SSO_LINK_FAILED',
        message: 'Something went wrong',
      });
    }
  } catch (error) {
    yield put({
      type: 'UI_AUTH_SEND_SSO_LINK_FAILED',
      message: error.message || 'Wrong request.',
    });
  }
}

function* resetNewPasswordSettings() {
  yield put({ type: 'RESET_NEW_PASSWORD_SETTINGS_SUCCESS' });
}

function* logout() {
  yield put({ type: 'UI_AUTH_SET_CLEAR_IFRAME_DATA', value: true });
  clearJwtData();
  LocalStorage.removeItem('current_user');
  LocalStorage.removeItem('dispatcher_companies');
  window.location.href = `${window.location.origin}${prefix}/login`;
  triggerWindowEvent(CUSTOM_EVENT_NAMES.logout);
  yield;
}
