import MuiFormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getValue } from '@yojee/helpers/access-helper';
import { Dialog, Input, ManageTable, ReactSelect, TextArea, useTable } from '@yojee/ui/base';

import { CONST_VARIABLE, MAX_LENGTH, MUST_HAVE_READ_PERMISSION_LIST } from './constants';
import useRow from './useRow';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBot: theme.spacing(3),
  },
  bold: {
    fontWeight: 600,
  },
  errorPermission: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  noHorizontalScrolling: {
    '& table': {
      minWidth: '100%',
    },
  },
  iconExpand: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  textExpand: {
    paddingLeft: theme.spacing(1),
  },
  childExpand: {
    paddingLeft: theme.spacing(4),
  },
}));

const headCells = [
  { id: 'name', numeric: false, label: <Trans>Access To</Trans>, width: '34%' },
  { id: 'checkBoxRead', numeric: false, label: <Trans>View</Trans>, width: '33%' },
  { id: 'checkBoxEdit', numeric: false, label: <Trans>Edit</Trans>, width: '33%' },
];

const ModifyRoleDialog = ({ role = undefined, onClose, onSave, open, allowedCompanyIds, ...others }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const errorTableRef = useRef(null);
  const [data, setData] = useState(
    role
      ? {
          name: role.name,
          company_id: role.company_id,
          description: role.description,
        }
      : {
          name: undefined,
          company_id: undefined,
          description: undefined,
        }
  );

  const [permissions, setPermissions] = useState(role ? role.permissions.map((p) => p.name) : []);

  const [isInProgress, setIsInProgress] = useState(false);

  const isActionInProgress = useSelector(
    (state) =>
      getValue(state, 'users.inProgress.requestCreateRole') || getValue(state, 'users.inProgress.requestUpdateRole')
  );
  const errorMessage = useSelector((state) => state.users?.errorMessage);
  const successMessage = useSelector((state) => state.users?.successMessage);
  const relatedCompanies = useSelector((state) => state.auth?.relatedCompanies || []);
  const availablePermissions = useSelector((state) => state.users?.permissions?.list || []);
  const [error, setError] = useState({
    [CONST_VARIABLE.NAME]: '',
    [CONST_VARIABLE.COMPANY]: '',
    [CONST_VARIABLE.DESCRIPTION]: '',
    [CONST_VARIABLE.PERMISSION]: '',
  });
  const setErrorHelper = (key, value) => {
    setError((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  useEffect(() => {
    if (isActionInProgress && !isInProgress) {
      setIsInProgress(true);
    } else if (isInProgress) {
      if (successMessage) {
        handleClose();
        setIsInProgress(false);
      } else if (errorMessage) {
        setIsInProgress(false);
      }
    }
  }, [isActionInProgress]);

  const handleClose = () => {
    setData(undefined);
    onClose();
  };

  const isValid = () => {
    let valid = true;
    let firstRef = null;
    const checkIfRequired = (key) => {
      valid = false;
      setErrorHelper(key, 'This field is required');
      if (!firstRef) firstRef = inputRef;
    };
    if (!data.name) checkIfRequired(CONST_VARIABLE.NAME);
    if (!data.company_id) checkIfRequired(CONST_VARIABLE.COMPANY);
    firstRef?.current?.scrollIntoView({ behavior: 'smooth' });
    return valid;
  };

  const handleSave = () => {
    if (!isValid()) return false;
    const _accessMap = {};
    availablePermissions.forEach((p) => {
      _accessMap[p.name] = permissions.includes(p.name);
    });
    MUST_HAVE_READ_PERMISSION_LIST.forEach((p) => {
      _accessMap[p] = true;
    });
    onSave(role && role.id, {
      ...data,
      access_map: _accessMap,
    });
  };

  const options = relatedCompanies
    .filter((c) => allowedCompanyIds.includes(c.company_id))
    .map((c) => {
      return {
        value: c.company_id,
        label: `${c.company_name} - ${c.company_id}`,
      };
    });

  const { rows } = useRow({ data, permissions, setPermissions, setErrorHelper });
  const { selected, isSelected, handleClick, handleSelectAllClick } = useTable({ rows });

  const tableConfig = {
    id: 'YJTableAllDrivers',
    numSelected: selected.length,
    rowCount: rows.length,
    columns: headCells,
    data: rows,
    showCheckBox: false,
    isSelected: isSelected,
    onRowClick: handleClick,
    onSelectAllClick: handleSelectAllClick,
    rowActions: [],
    className: classes.noHorizontalScrolling,
  };

  return (
    <Dialog
      onClose={handleClose}
      title={role ? <Trans>Edit Role</Trans> : <Trans>Create Role</Trans>}
      onSave={handleSave}
      data={data}
      open={open}
      dividers="paper"
      isLoading={isActionInProgress}
      {...others}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={6} ref={inputRef}>
          <Input
            fullWidth
            label={<Trans>Name</Trans>}
            required={true}
            disabled={data?.name?.toLowerCase() === 'admin'}
            value={(data && data.name) || ''}
            onChange={(event) => {
              setData({ ...data, name: event.target.value });
              setErrorHelper(CONST_VARIABLE.NAME);
            }}
            helperText={error[CONST_VARIABLE.NAME]}
            error={error[CONST_VARIABLE.NAME]?.length > 0}
          />
        </Grid>
        <Grid item xs={6}>
          <ReactSelect
            value={
              (data &&
                data.company_id && {
                  value: data.company_id,
                  label: options.find((option) => option.value === data.company_id)?.label,
                }) ||
              ''
            }
            options={options}
            onChange={({ value }) => {
              setData({
                ...data,
                company_id: value,
              });
              setErrorHelper(CONST_VARIABLE.COMPANY);
            }}
            isMulti={false}
            label={<Trans i18nKey="companyWorkspace">Company workspace</Trans>}
            required={true}
            helperText={error[CONST_VARIABLE.COMPANY]}
            error={error[CONST_VARIABLE.COMPANY]?.length > 0}
            disabled={!!role}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            fullWidth
            label={<Trans>Description</Trans>}
            required={false}
            disabled={false}
            multiline
            rows={9}
            maxRows={9}
            value={(data && data.description) || ''}
            maxLength={MAX_LENGTH}
            showWordCount={true}
            onChange={(event) => {
              setData({ ...data, description: event.target.value.substring(0, MAX_LENGTH) });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ManageTable
            searchInput={null}
            actionBtnsShow={false}
            actionBtns={null}
            pagination={null}
            tableConfig={tableConfig}
          />
        </Grid>
        {error[CONST_VARIABLE.PERMISSION] && (
          <MuiFormHelperText ref={errorTableRef} className={classes.errorPermission} error={true}>
            {error[CONST_VARIABLE.PERMISSION]}
          </MuiFormHelperText>
        )}
      </Grid>
    </Dialog>
  );
};

export default ModifyRoleDialog;
