import { APIAxiosInstance } from '@yojee/api/authService/dispatcherAuthService';
import { getHeaders } from '@yojee/api/common';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { BATCH_TEMPLATE_TYPES } from '@yojee/helpers/constants';
import getEnv from '@yojee/helpers/env/getEnv';

import { getAllocationBody, getAssignmentBody, getReAssignedBody } from './helpers/AssignHelper';

const API_URL = getEnv('REACT_APP_API_URL');

export function login(params, { slug }) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams && urlParams.get('refresh_expiry')) {
    const expiryInSeconds = parseInt(urlParams.get('refresh_expiry'), 10) || 60 * 60 * 24 * 7;
    //  backdoor to set refresh_token expiry time in seconds
    //  use it to test case when refresh token expires
    //  by default, refresh_token only expires after 1 week
    params['refresh_expires_in'] = expiryInSeconds;
  }
  return APIAxiosInstance.post(`${API_URL}/auth/signin`, params, { headers: getHeaders({ slug }) });
}

export function signUp(payload) {
  return APIAxiosInstance.post(`${API_URL}/public/companies`, payload);
}

export function fetchTransferedTasks(params = {}, { token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/tasks`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: params,
  });
}

export function fetchTasks(params = {}, { token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/power/tasks`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: params,
  });
}

export function fetchHierarchy({ orderItemId, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/order_items/${orderItemId}/hierarchy`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function fetchWorkers({
  token,
  partnerJwt,
  slug,
  driverName,
  centroid,
  sort = 'last_seen',
  order,
  pageSize = 1000,
  page = 1,
  from = null,
  to = null,
}) {
  if (driverName) {
    return APIAxiosInstance.get(`${API_URL}/dispatcher/workers`, {
      headers: getHeaders({ token, slug, partnerJwt }),
      params: getWorkerParams({ name: driverName, page_size: pageSize, page, from, to }),
    });
  }

  const targetLocation = centroid && centroid.lat && centroid.lng ? `${centroid.lat},${centroid.lng}` : '';
  return APIAxiosInstance.get(`${API_URL}/dispatcher/workers`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: getWorkerParams({
      page_size: pageSize,
      page,
      from,
      to,
      sort,
      target_location: targetLocation,
      order: order ? order : 'desc',
    }),
  });
}

export function fetchWorker({ token, partnerJwt, slug, id }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/workers/${id}`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

const getWorkerParams = (additionalParams = {}) => {
  !additionalParams.target_location && delete additionalParams.target_location;
  return {
    ...additionalParams,
  };
};

export function assign({ tasks, workers, vehicleNumber, commissionJson, auth: { token, slug, partnerJwt } }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/quick_assign`,
    getAssignmentBody({ tasks, workers, vehicleNumber, commissionJson }),
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function assignBg({
  tasks,
  workers,
  vehicleNumber,
  commissionJson,
  isContactBasedAllocation,
  optimised,
  auth: { token, slug, partnerJwt },
}) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/quick_assign_bg`,
    getAssignmentBody({ tasks, workers, vehicleNumber, isContactBasedAllocation, optimised, commissionJson }),
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function reassign({ requestRoutes, tasks, workers, commissionJson, auth: { token, slug, partnerJwt } }) {
  const allocationBody = getReAssignedBody(requestRoutes, tasks, workers, commissionJson);
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/allocate`,
    { ...allocationBody, mode: 'reassign' },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function reassignBg({
  requestRoutes,
  tasks,
  workers,
  commissionJson,
  optimised,
  isContactBasedAllocation,
  auth: { token, slug, partnerJwt },
}) {
  const allocationBody = getReAssignedBody(requestRoutes, tasks, workers, commissionJson, optimised);
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/allocate_bg`,
    { ...allocationBody, mode: 'reassign', consolidate_based_on_address: isContactBasedAllocation },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function allocateBg({
  requestRoutes,
  tasks,
  workers,
  commissionJson,
  optimised,
  droppedTasks,
  epochDate,
  auth: { token, slug, partnerJwt },
}) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/allocate_bg`,
    getAllocationBody(requestRoutes, tasks, workers, commissionJson, optimised, droppedTasks, epochDate),
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function getAllocateStatus(id, { auth: { token, slug, partnerJwt } }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/tasks/bg_status`,
    { id: id },
    { headers: getHeaders({ token, slug, partnerJwt }) }
  );
}

export function searchTask({ searchText, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/search/order_item`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      q: searchText,
      page_size: 100,
      page: 1,
    },
  });
}

export function fetchAllTeam({ token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/worker_teams`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      page_size: 1000,
      page: 1,
    },
  });
}

export function fetchTasksByField(field, value, { token, partnerJwt, slug, page, pageSize }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/power/tasks`,
    {
      [field]: value,
      task_query_type: 'all',
      query_version: 2,
      page: page || 1,
      limit: pageSize || 100,
      cancelled: `false`,
      with_parent_consolidations: `true`,
    },
    {
      headers: getHeaders({ token, slug, partnerJwt }),
    }
  );
}

export function getSampleUrl(format, type, { slug }) {
  const endpoint = type === BATCH_TEMPLATE_TYPES.MULTIPLE_LEGS.id ? 'download_multileg_sample' : 'download_sample';
  const typeParam = type === BATCH_TEMPLATE_TYPES.SINGLE_TASK.id ? `&type=${type}` : '';
  return `${API_URL}/public/orders/${endpoint}?company_slug=${slug}&format=${format}${typeParam}`;
}

export function searchOrganization({ searchText, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/organisations`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      q: searchText,
      page_size: 100,
      page: 1,
    },
  });
}

export function getOrganisations({ token, partnerJwt, slug, pageSize, pageNo }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/organisations`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      page_size: pageSize || 100,
      page: pageNo || 1,
    },
  });
}

export function getSenderByOrganizationId({ organizationId, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/organisations/${organizationId}/senders`, {
    headers: getHeaders({ token, slug, partnerJwt }),
    params: {
      page_size: 100,
      page: 1,
    },
  });
}

export function fetchCompanySettings({ token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/company`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function getSubscriptions({ token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/company/subscriptions`, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function updateOrder({ body, orderNumber, token, slug, partnerJwt }) {
  return APIAxiosInstance.put(
    `${API_URL}/dispatcher/orders/${orderNumber}`,
    { order: body },
    {
      headers: getHeaders({ token, slug, partnerJwt }),
    }
  );
}

export function consolidate({ body, token, slug, partnerJwt }) {
  return APIAxiosInstance.post(`${API_URL}/dispatcher/order_items/consolidate`, body, {
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function deconsolidate({ token, partnerJwt, slug, parentTrackingNumber, childTrackingNumbers }) {
  return APIAxiosInstance.post(
    `${API_URL}/dispatcher/order_items/deconsolidate`,
    { parent_tracking_number: parentTrackingNumber, child_tracking_numbers: childTrackingNumbers },
    {
      headers: getHeaders({ token, slug, partnerJwt }),
    }
  );
}

export function getCompanyPublicInfo() {
  return APIAxiosInstance.get(`${API_URL}/public/orders/company_info`, {
    params: { company_slug: getSlugFromUrl() },
  });
}

export function requestNewPassword({ email, company_slug }) {
  return APIAxiosInstance.post(`${API_URL}/public/password`, {
    email,
    company_slug,
  });
}

export function editPassword({ token, password }) {
  return APIAxiosInstance.put(`${API_URL}/public/password`, {
    reset_password_token: token,
    password,
  });
}

export function fetchVehicles({
  searchTerm = '',
  page = 1,
  pageSize = 50,
  startDate,
  endDate,
  token,
  slug,
  partnerJwt,
}) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/company/vehicles`, {
    params: {
      page,
      page_size: pageSize,
      ...(searchTerm ? { q: searchTerm } : {}),
      from: startDate,
      to: endDate,
      active: true,
    },
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function fetchVehicleSchedules({ id, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/company/schedules`, {
    params: { vehicle_id: id },
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function searchVehicles({
  searchTerm = '',
  vehicleTypeId = null,
  from = null,
  to = null,
  q,
  page,
  page_size,
  token,
  slug,
  partnerJwt,
}) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/search/vehicle`, {
    params: {
      ...(vehicleTypeId ? { vehicle_type_id: vehicleTypeId } : {}),
      ...(searchTerm ? { q: searchTerm } : {}),
      from,
      to,
      active: true,
      page,
      page_size,
      q,
    },
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function searchWorkers({ searchTerm = '', page = 1, pageSize = 50, takeAll = false, token, slug, partnerJwt }) {
  return APIAxiosInstance.get(`${API_URL}/dispatcher/search/worker`, {
    params: {
      ...(takeAll
        ? {
            take_all: true,
          }
        : {
            page,
            page_size: pageSize,
          }),
      q: searchTerm,
    },
    headers: getHeaders({ token, slug, partnerJwt }),
  });
}

export function assignVehicleToDriver({ driverId, vehicleId, token, slug, partnerJwt }) {
  return APIAxiosInstance.put(
    `${API_URL}/dispatcher/workers/${driverId}/assign_vehicle`,
    { vehicle_id: vehicleId },
    {
      headers: getHeaders({ token, slug, partnerJwt }),
    }
  );
}
