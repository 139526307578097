import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { getHeaders } from './common/common';

const getWorkerParams = (additionalParams = {}) => {
  !additionalParams.target_location && delete additionalParams.target_location;
  return {
    ...additionalParams,
  };
};

export class WorkersService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  fetchWorkersById = ({ token, partnerJwt, slug, ids, pageSize = 1000, page = 1 }) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl('dispatcher/workers'),
        getWorkerParams({ page_size: pageSize, page, worker_ids: ids }),
        {
          headers: getHeaders({ token, slug, partnerJwt }),
        }
      )
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  fetchWorkersSchedule = ({ workerIds }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/schedules'), { worker_ids: workerIds })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const workersService = new WorkersService({ auth: authService });
