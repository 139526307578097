import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import CustomUncheckedIcon from './CustomUncheckedIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(2),
    '& .innerBoxYojee': {
      fill: (props) => (props.disabled ? theme.palette?.black?.B10 : 'none'),
    },
    '& .MuiCheckbox-colorPrimary': {
      '&:hover': {
        color: (props) => theme.palette[props.color]?.main,
      },
    },
  },
  icon: {},
}));

/**
 * @param {{ dataCy?: string, size?: import('@material-ui/core').CheckboxProps['size'] } & Omit<import('@material-ui/core').FormControlLabelProps, 'control'>} args
 */
const Checkbox = (args) => {
  const { checked, onChange, value, className, color, label, dataCy, size, ...rest } = args;
  const classes = useStyles(args);
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          size={size}
          checked={checked}
          color={color}
          className={classes.icon}
          onChange={onChange}
          value={value}
          icon={<CustomUncheckedIcon />}
          data-cy={dataCy}
        />
      }
      classes={{
        root: classes.root,
      }}
      className={clsx(className)}
      label={label}
      {...rest}
    />
  );
};

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
