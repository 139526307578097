import { Trans } from 'react-i18next';

export const getTaskGroupForBroadcast = ({ taskIds, tasks }) => {
  const selectedTaskGroupIds = taskIds
    .filter((t) => {
      const taskGroup = tasks?.[t]?.task_group;
      return (
        taskGroup?.id &&
        taskGroup?.state === 'unassigned' &&
        !taskGroup?.accepted_time &&
        !taskGroup?.assigned_time &&
        !taskGroup?.broadcast_expires_at &&
        tasks?.[t]?.task?.state === 'created'
      );
    })
    .map((t) => tasks?.[t]?.task_group.id);
  return [...new Set(selectedTaskGroupIds)];
};

export const getTaskIsBroadcasting = ({ taskIds, tasks }) => {
  if (!tasks || !taskIds || taskIds.length < 1) {
    return [];
  }
  const taskIdBroadcasting = taskIds.filter((t) => isTaskBroadcasting(tasks[t]));
  return [...new Set(taskIdBroadcasting)];
};

export const BROADCAST_STATUS = {
  ACCEPTED: 'ACCEPTED',
  BROADCASTING: 'BROADCASTING',
};

export const BROADCAST_STATUS_KEY_TEXT_MAP = {
  [BROADCAST_STATUS.ACCEPTED]: <Trans>ACCEPTED</Trans>,
  [BROADCAST_STATUS.BROADCASTING]: <Trans>BROADCASTING</Trans>,
};

export const getBroadcastStatus = (task) => {
  if (task?.task_group?.accepted_time && task?.task_group?.state === 'assigned' && task?.task?.state !== 'completed') {
    return BROADCAST_STATUS.ACCEPTED;
  }
  if (isTaskBroadcasting(task)) {
    return BROADCAST_STATUS.BROADCASTING;
  }
  return undefined;
};

export const isTaskBroadcasting = (task) => {
  return (
    task?.task_group?.broadcast_expires_at &&
    !task?.task_group?.accepted_time &&
    task?.task_group?.state === 'unassigned'
  );
};
