import { MuiThemeProvider } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { saveAs } from 'file-saver';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { userService } from '@yojee/api/userService';
import { TOOLTIP_DISABLED } from '@yojee/helpers/constants';
import DialogConfirm from '@yojee/ui/base/corners/DialogConfirm';
import useTable from '@yojee/ui/base/customHooks/useTable';
import ListManageTemplate from '@yojee/ui/base/houses/ListManagement';
import { theme } from '@yojee/ui/base/theme';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import { UIAuthActions } from '../../sagas/auth/actions';
import { UIUserManagementActions } from '../../sagas/userManagement/actions';
import ModifyUserDialog from './sub/modify/ModifyUserDialog';
import UserSnackBar from './sub/UserSnackBar';

const createData = (id, name, email, company, phone, role) => {
  return { id, name, email, company, phone, role };
};

const headCells = [
  { id: 'id', numeric: true, label: <Trans>ID</Trans>, 'data-cy': 'cell-id', width: '10%' },
  { id: 'name', numeric: false, label: <Trans>Name</Trans>, 'data-cy': 'cell-name', width: '20%' },
  { id: 'email', numeric: false, label: <Trans>Email</Trans>, 'data-cy': 'cell-email', width: '25%' },
  { id: 'company', numeric: false, label: <Trans>Company</Trans>, 'data-cy': 'cell-company', width: '10%' },
  { id: 'phone', numeric: false, label: <Trans>Phone</Trans>, 'data-cy': 'cell-phone', width: '15%' },
  { id: 'role', numeric: false, label: <Trans>Role name</Trans>, 'data-cy': 'cell-role', width: '100%' },
];

export const UserManagement = ({ openDialog, BatchUploadContent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const readOnly = useContext(ReadOnlyContext);
  const [isOpenConfirmDeletionDialog, setIsOpenConfirmDeletionDialog] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [searchText, setSearchText] = useState('');

  const isLoading = useSelector(
    (state) =>
      state.users.inProgress.requestGetUsers ||
      state.users.inProgress.requestCreateUser ||
      state.users.inProgress.requestUpdateUser
  );
  const users = useSelector((state) => state.users?.info?.users ?? [], shallowEqual);
  const pagination = useSelector((state) => state.users.info.pagination);

  useEffect(() => {
    if (openDialog === false && isDialogOpen === true) {
      setIsDialogOpen(false);
    }
  }, [isDialogOpen, openDialog]);

  useEffect(() => {
    loadUsers({});
    dispatch(
      UIUserManagementActions.requestGetRoles({
        page: 1,
        page_size: 100000,
        all_companies: true,
      })
    );
    dispatch(UIUserManagementActions.requestGetFilters());
    dispatch(UIAuthActions.loadRelatedCompanies());
    dispatch(UIAuthActions.loadDispatcherInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = ({ page, page_size, q = null } = {}) => {
    const { current_page, limit_value } = pagination;
    dispatch(
      UIUserManagementActions.requestGetUsers({
        page: page || current_page,
        page_size: page_size || limit_value,
        q: q === null ? searchText : q,
      })
    );
  };

  const rows = React.useMemo(() => {
    if (!!users?.length === false) return [];

    return users?.map((user) =>
      createData(
        user.user_profile_id,
        user.name,
        user.email,
        user?.companies &&
          Object.keys(user?.companies)
            ?.map((key) => user?.companies?.[key]?.company_name)
            ?.join(', '),
        user.phone,
        user?.companies &&
          Object.keys(user?.companies)
            ?.map((key) => user?.companies?.[key]?.roles?.map((role) => role?.name))
            ?.flat(1)
            ?.join(', ')
      )
    );
  }, [users]);

  const {
    selected,
    isSelected,
    setSelected,
    handleClick,
    handleSelectAllClick,
    getSingleDataById,
    DEBOUNCE_SEARCH_TIME,
  } = useTable({
    rows,
    data: users,
  });

  const primaryBtns = [
    {
      label: <Trans>Add User</Trans>,
      color: 'primary',
      onClick: () => {
        setIsDialogOpen(true);
        setCurrentUser(undefined);
      },
      disabled: isLoading || readOnly,
      'data-cy': 'single-add',
      tooltip: readOnly ? TOOLTIP_DISABLED : '',
    },
  ];

  const searchHandler = (event) => {
    setSearchText(event.target.value);
    searchQuery(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuery = React.useCallback(
    debounce((q) => {
      loadUsers({ page: 1, page_size: pagination.page_size, q });
    }, DEBOUNCE_SEARCH_TIME),
    []
  );

  const searchInput = {
    icon: <SearchIcon />,
    onChange: searchHandler,
    value: searchText,
    'data-cy': 'search',
  };

  const actionBtns = [
    {
      label: <Trans>Download</Trans>,
      onClick: () => {
        userService.downloadMasterData({ ids: selected, type: 'enterprise_user' }).then((data) => {
          const url = URL.createObjectURL(new Blob([data], { type: 'data:text/csv;charset=utf-8' }));
          saveAs(url, `enterprise_users_data_${selected.length}.csv`);
        });
      },
      disabled: false,
    },
  ];

  const actionBtnsShow = selected?.length > 0;

  const tableConfig = {
    id: 'YJTableAllEnterpriseUsers',
    numSelected: selected.length,
    rowCount: rows.length,
    columns: headCells,
    data: rows,
    showCheckBox: true,
    isSelected: isSelected,
    onRowClick: handleClick,
    onSelectAllClick: handleSelectAllClick,
    dataCyTable: 'table',
    dataCyRow: 'row',
    dataCyCheckbox: 'cell-checkbox',
    rowActions: [
      {
        type: 'edit',
        title: <Trans>Edit</Trans>,
        icon: <EditIcon color="primary" fontSize="small" />,
        onClick: (row) => {
          setCurrentUser(getSingleDataById(row.id, 'user_profile_id'));
          setIsDialogOpen(true);
        },
        'data-cy': 'single-edit',
        conditions: () => !readOnly,
      },
      {
        type: 'delete',
        title: <Trans>Delete</Trans>,
        icon: <DeleteIcon color="primary" fontSize="small" />,
        onClick: (row) => {
          setIsOpenConfirmDeletionDialog(true);
          setCurrentUser(getSingleDataById(row.id, 'user_profile_id'));
        },
        'data-cy': 'single-delete',
        conditions: () => !readOnly,
      },
    ],
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <ListManageTemplate
          title={t('User Management')}
          primaryBtns={primaryBtns}
          actionBtnsShow={actionBtnsShow}
          actionBtns={actionBtns}
          searchInput={searchInput}
          tableConfig={tableConfig}
          t
          loadingConfig={{ pageLoading: false, tableLoading: isLoading }}
          pagination={{
            pagination,
            'data-cy': 'management-pagination',
            onChangePage: loadUsers,
            onChangeRowsPerPage: loadUsers,
          }}
        />
        <ModifyUserDialog
          open={isDialogOpen}
          user={currentUser}
          onSave={(userId, user) => {
            if (!userId) {
              dispatch(UIUserManagementActions.requestCreateUser(user));
            } else {
              dispatch(UIUserManagementActions.requestUpdateUser({ userId, data: user }));
            }
          }}
          onClose={() => {
            setIsDialogOpen(false);
            setCurrentUser(undefined);
          }}
          BatchUploadContent={BatchUploadContent}
          onSuccessBatchUpload={loadUsers}
          isLoading={isLoading}
        />
        <DialogConfirm
          title={<Trans i18nKey="deleteDialogTitle" />}
          open={isOpenConfirmDeletionDialog}
          onClose={() => {
            setIsOpenConfirmDeletionDialog(false);
            setCurrentUser(undefined);
          }}
          onSave={() => {
            setIsOpenConfirmDeletionDialog(false);
            dispatch(UIUserManagementActions.requestDeleteUser({ userId: currentUser.user_profile_id }));
            setCurrentUser(undefined);
            setSelected([]);
          }}
          uploadItemType="user"
          dividers="paper"
        >
          <Trans i18nKey="confirmToDeleteItem" />
        </DialogConfirm>
      </MuiThemeProvider>
      <UserSnackBar />
    </>
  );
};

export default UserManagement;
