import i18next from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { companyService } from '@yojee/api/companyService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { LocalStorage } from '@yojee/local-storage/local-storage';

import * as Api from '../../Api/api';

export default function* sagas() {
  yield takeLatest('REQUEST_COMPANY_PUBLIC_INFO', requestCompanyPublicInfo);
  yield takeLatest('REQUEST_COMPANY_SUBSCRIPTIONS', requestCompanySubscriptions);
  yield takeLatest('GET_COMPANY_INFO', getCompanyInfo);
  yield takeLatest('GET_TASK_TYPES', getTaskTypes);
  yield takeLatest('GET_DASHBOARD_URL', getDashboardUrl);
  yield takeLatest('GET_COMPANY_REPORTS', getCompanyReports);
  yield takeLatest('SET_COMPANY_INFO', setCompanyInfo);
}

function* requestCompanyPublicInfo(action) {
  try {
    yield put({ type: 'COMPANY_START_LOADING', loadingAction: 'getCompanyPublicInfo' });
    const {
      data: { data },
    } = yield call(Api.getCompanyPublicInfo, { company_slug: action.payload });
    const language = data.dispatcher && data.dispatcher.language;
    if (language) {
      i18next.changeLanguage(language);
    }
    yield put({ type: 'REQUEST_COMPANY_PUBLIC_INFO_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'REQUEST_COMPANY_PUBLIC_INFO_FAILED', error });
  }
}

function* requestCompanySubscriptions() {
  try {
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: {
        data: { subscriptions },
      },
    } = yield call(Api.getSubscriptions, { token, partnerJwt, slug });
    yield put({ type: 'REQUEST_COMPANY_SUBSCRIPTIONS_SUCCESS', subscriptions });
  } catch (error) {
    yield put({ type: 'REQUEST_COMPANY_SUBSCRIPTIONS_FAILED', error });
  }
}

function* setCompanyInfo({ payload: { companyData } }) {
  yield put({ type: 'GET_COMPANY_INFO_SUCCESSFUL', companyData });
}

function* getCompanyInfo() {
  if (LocalStorage.getItem('access_token')) {
    try {
      yield put({ type: 'START_LOADING', loadingAction: 'getCompanyInfo' });
      const companyData = yield call(companyService.getCompanyInfo);

      yield setCompanyInfo({ payload: { companyData } });
    } catch (error) {
      yield put({ type: 'GET_COMPANY_INFO_FAILED', error });
    }
  }
}

function* getTaskTypes() {
  try {
    const {
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    yield put({ type: 'START_LOADING', loadingAction: 'getTaskTypes' });
    const data = yield call(companyService.getTaskTypes, { company_slug: slug });
    yield put({ type: 'GET_TASK_TYPES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_TASK_TYPE_FAILED', error });
  }
}

function* getDashboardUrl(action) {
  try {
    const {
      payload: { type },
    } = action;
    yield put({ type: 'START_LOADING', loadingAction: 'getDashboardUrl' });
    const data = yield call(companyService.getDashboardUrl, type);
    yield put({ type: 'GET_DASHBOARD_URL_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_DASHBOARD_URL_FAILED', error });
  }
}

function* getCompanyReports(action) {
  try {
    const {
      payload: { type, provider },
    } = action;
    yield put({ type: 'START_LOADING', loadingAction: 'getCompanyReports' });
    const data = yield call(companyService.getCompanyReports, type, provider);
    yield put({ type: 'GET_COMPANY_REPORTS_SUCCESSFUL', data: { data, type } });
  } catch (error) {
    yield put({ type: 'GET_COMPANY_REPORTS_FAILED', error });
  }
}
