import { groupBy } from 'lodash';
import _ from 'lodash-es';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { rrulestr } from 'rrule';

import { hubsService } from '@yojee/api/hubsService';
import { regionService } from '@yojee/api/regionsService';
import { vehicleTypesService } from '@yojee/api/vehicleTypesService';
import { CONNECTION_TYPES, webSocketService } from '@yojee/api/webSocketService';
import AuthSelectors from '@yojee/auth/store/selectors';
import { initTasks, loadMoreTask } from '@yojee/data/fetch-services/tasks';
import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS, MAX_AMOUNT_OF_SELECTED_TASKS, SOCKET_EVENTS } from '@yojee/helpers/constants';
import { getDurationInSeconds } from '@yojee/helpers/planner-helper';
import { allAvailableSelectTasks, getTaskStatus } from '@yojee/helpers/stop-helper';
import { MapController } from '@yojee/ui/map';

import * as Api from '../../Api/api';
import { findCentroid } from '../../helpers/calculateLocationHelper';
import { store } from '../../index';
import { findAssignedWorkers } from '../../reducers/planner';
import { selectAllTasksByFilter } from '../tasks/saga';

const moment = extendMoment(Moment);

// State Access Functions
export const getWorkers = (state) => state.worker && state.worker.data;
export const getPlannerData = (state) => state.planner;
export const getTaskIds = (state) => state.planner && state.planner.taskData.ids;
export const getTaskData = (state) => state.planner && state.planner.taskData.data;
export const getSelectedTasks = (state) => state.planner && state.planner.selectedTasks;
export const getSelectedItemsByStepGroup = (state) => state.planner && state.planner.selectedItemsByStepGroup;
export const getSelectedWorkers = (state) => state.planner && state.planner.selectedWorkers;
export const getSelectedHubs = (state) => state.planner && state.planner.selectedHubs;
export const getZonesData = (state) => state.planner && state.planner.regionsData;
export const getAssignVehicleToDriverInfo = (state) => state.planner && state.planner.assignVehicleToDriver;
export const getSelectedWorker = (state) => state.planner?.filters?.workers;
export const getWorkerFilter = (state) => state.planner?.workerData?.filters ?? {};
export const getVehiclesSearchInfo = (state) => getValue(state, 'planner.vehiclesSearch');
export const getCurrentStopView = (state) => state.main && state.main.stopsList.currentView;
export const getCurrentListView = (state) => state.itemsTable && state.itemsTable.currentView;
export const getShowList = (state) => state.main && state.main.showList;
export const getStopFilter = (state) => state.main && state.main.stopsList && state.main.stopsList.filter;
export const getRightPanelCommand = (state) => state.main && state.main.rightPanelCommand;
export const getMissingInfoFixMode = (state) => state.masterFilter?.missingInfoMode;

export const getNumberOfLoadedTasks = (state) => {
  if (state.planner) {
    let data = state.planner.taskData.data || {};
    data = Object.values(data);
    data = groupBy(data, (d) => {
      return `${d.order_item_id}_${d.step_group}`;
    });
    const loadedItemTotal = Object.keys(data).length;

    const loadedTasksTotal = state.planner.taskData.ids?.length ?? 0;
    return {
      loadedItemTotal: loadedItemTotal,
      loadedTasksTotal: loadedTasksTotal,
    };
  }
  return {
    loadedItemTotal: 0,
    loadedTasksTotal: 0,
  };
};
const getShowCreateOrderSuccessMessage = (state) =>
  state.auth &&
  state.auth.dispatcher_info &&
  _.get(state.auth.dispatcher_info, 'data.company.display_settings.admin_access') &&
  _.get(state.auth.dispatcher_info, 'data.company.display_settings.admin_access')[
    'notifications.show.create_order_success_message'
  ];
const getCurrentTaskFilter = (state) => state.planner && state.planner.filters && state.planner.filters.taskFilter;

let ETA_DATA_BATCH = {};
let WORKER_DATA_UPDATED_BATCH = {};
const prefix = process.env.REACT_APP_PREFIX || '';

function* initAuth() {
  const { token, partnerJwt } = yield select(AuthSelectors.getData);
  if (!token && !partnerJwt) {
    window.location.href = `${window.location.origin}${prefix}/login`;
  }
}

function* initNetwork() {
  try {
    yield put({ type: 'FETCH_HUBS_STARTED' });
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const hubsData = yield call(hubsService.fetchHubs, { token, partnerJwt, slug });
    yield put({ type: 'FETCH_HUBS_SUCCEEDED', data: hubsData });
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}

function* initRegions() {
  try {
    const { data: regionsData } = yield call(regionService.fetchRegions);
    yield put({ type: 'FETCH_REGIONS_SUCCEEDED', data: regionsData });
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}

function* initSettings() {
  const {
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const { data: settings } = yield call(Api.fetchSettings, slug || 'default');
  yield put({ type: 'FETCH_SETTINGS_SUCCEEDED', data: settings });
}

function* initVehicleTypes() {
  try {
    const { data: vehicleData, pagination } = yield call(vehicleTypesService.fetchVehicleTypes);
    let mergedData = vehicleData;
    const max_pages = Math.min(pagination.total_pages, 20); // Limit to max 10 pages
    if (pagination.total_pages > 1) {
      for (let i = 2; i <= max_pages; i++) {
        const { data: _vehicleData } = yield call(vehicleTypesService.fetchVehicleTypes, i);
        mergedData = mergedData.concat(_vehicleData);
      }
    }
    yield put({ type: 'FETCH_VEHICLE_TYPES_SUCCEEDED', vehicleTypes: mergedData });
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}

function* refreshCompanySetting() {
  try {
    yield put({ type: 'PLANNER_LOADING', key: 'companySettings' });
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const { data: companyInfo } = yield call(Api.fetchCompanySettings, { token, partnerJwt, slug });
    companyInfo.data.subscribed = true;
    if (companyInfo.data.status === 'trial') {
      const {
        data: {
          data: { subscriptions },
        },
      } = yield call(Api.getSubscriptions, { token, slug, partnerJwt });
      if (subscriptions.length) {
        subscriptions.forEach((subscription) => {
          if (subscription.cancelled_at) {
            companyInfo.data.subscribed = false;
          }
        });
      } else {
        companyInfo.data.subscribed = false;
      }
    }
    yield put({ type: 'UI_AUTH_UPDATE_COMPANY_SETTINGS', companyInfo });
    yield put({ type: 'INIT_SOCKET' });
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}

function* initWorkers() {
  try {
    yield put({ type: 'PLANNER_LOADING', key: 'getWorkers' });
    yield put({ type: 'INIT_VEHICLE_TYPES' });

    const allCurrentTasks = yield select(getTaskData);
    const allCurrentTasksIds = yield select(getTaskIds);
    const allSelectedTaskId = yield select(getSelectedTasks);

    if (!allCurrentTasksIds) {
      return;
    }
    const { distanceType } = yield select(getWorkerFilter);
    let centroid = null;
    // filter task by selected task id
    const selectedTasksIds = allCurrentTasksIds
      .filter((id) => !allSelectedTaskId || allSelectedTaskId.length === 0 || allSelectedTaskId.includes(id))
      .filter((id) => distanceType === 'all' || distanceType === allCurrentTasks[id].type);
    if (selectedTasksIds && selectedTasksIds.length > 0) {
      const selectedTaskLocation = selectedTasksIds.map((id) => allCurrentTasks[id].location);
      centroid =
        selectedTaskLocation.length === 1
          ? [selectedTaskLocation[0].lat, selectedTaskLocation[0].lng]
          : findCentroid(selectedTaskLocation);
      if (centroid && centroid.length === 2) {
        centroid = { lat: centroid[0], lng: centroid[1] };
      }
    }

    const {
      filters: { startDate, endDate },
    } = yield select(getPlannerData);

    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const filterData = yield select(getWorkerFilter);
    const sortData = ['high_to_low', 'low_to_high'].includes(filterData.current)
      ? {
          sort: 'capacity_load',
          order: filterData.current === 'high_to_low' ? 'desc' : 'asc',
        }
      : { sort: filterData.current };

    const { data: workersData } = yield call(Api.fetchWorkers, {
      token,
      partnerJwt,
      slug,
      ...sortData,
      centroid,
      ...(filterData.filterType === 'all'
        ? {}
        : {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          }),
    });

    const { pagination } = workersData;
    const mergedData = workersData;
    const max_pages = Math.min(pagination.total_pages, 20); // Limit to max 10 pages
    if (pagination.total_pages > 1) {
      for (let i = 1; i < max_pages; i++) {
        const { data: _workersData } = yield call(Api.fetchWorkers, {
          token,
          slug,
          partnerJwt,
          ...sortData,
          centroid,
          page: i + 1,
          ...(filterData.filterType === 'all'
            ? {}
            : {
                from: startDate.toISOString(),
                to: endDate.toISOString(),
              }),
        });
        mergedData.data = mergedData.data.concat(_workersData.data);
        mergedData.pagination = _workersData.pagination;
      }
    }
    yield put({ type: 'VALIDATE_WORKER_CAPACITY_ERROR', workers: mergedData.data });
    yield put({ type: 'FETCH_PLANNER_WORKERS_SUCCEEDED', data: mergedData });
  } catch (err) {
    console.error('==== Planner init Worker Error', err.message);
  }
}

function* getWorker(action) {
  yield put({ type: 'PLANNER_LOADING', key: 'getWorker' });

  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    data: { data },
  } = yield call(Api.fetchWorker, { token, slug, partnerJwt, id: action.payload.workerId });

  yield put({ type: 'FETCH_WORKER_SUCCEEDED', data: data });
}

function* getVehicles(action) {
  yield put({ type: 'PLANNER_LOADING', key: 'getVehicles' });
  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    vehiclesData: {
      pagination: { limit_value: pageSize },
      searchTerm,
    },
    filters: { startDate, endDate },
  } = yield select(getPlannerData);

  const {
    data: { data, pagination },
  } = yield call(Api.fetchVehicles, {
    token,
    slug,
    partnerJwt,
    ...action.payload,
    pageSize,
    searchTerm,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  yield put({ type: 'FETCH_VEHICLES_SUCCEEDED', data, pagination });
}

function* getVehicleSchedules({ id }) {
  yield put({ type: 'PLANNER_LOADING', key: 'getVehicleSchedules' });
  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    data: { data },
  } = yield call(Api.fetchVehicleSchedules, { id, token, slug, partnerJwt });
  const { driver } = yield select(getAssignVehicleToDriverInfo);
  const { filterType: _driverFilterType } = yield select(getWorkerFilter);
  const { filterType: _vehicleFilterType } = yield select(getVehiclesSearchInfo);

  let overlaps = true;
  if ([_driverFilterType, _vehicleFilterType].includes('scheduled')) {
    const {
      filters: { startDate, endDate },
    } = yield select(getPlannerData);
    const mainFrom = startDate.clone();
    const mainTo = endDate.clone();

    const mainRange = moment.range(mainFrom, mainTo);

    overlaps = data.some((schedule) => {
      const vehicleScheduleFrom = moment(schedule.start_event_datetime);
      const vehicleScheduleTo = moment(schedule.end_event_datetime);
      const vehicleScheduleRange = moment.range(vehicleScheduleFrom, vehicleScheduleTo);

      if (mainRange.overlaps(vehicleScheduleRange)) {
        return driver.schedules.some((driverSchedule) => {
          const rule = rrulestr(driverSchedule.recurrence_rule);

          const driverEventDuration = getDurationInSeconds(
            moment(driverSchedule['start_event_datetime']).toDate(),
            moment(driverSchedule['end_event_datetime']).toDate()
          );

          return rule
            .between(mainFrom.clone().subtract(2, 'weeks').toDate(), mainTo.clone().add(2, 'weeks').toDate())
            .some((driverScheduleDate) => {
              const from = moment(driverScheduleDate);
              const to = moment(driverScheduleDate).add(driverEventDuration, 'seconds');
              const driverScheduleRange = moment.range(from, to);

              if (mainRange.overlaps(driverScheduleRange)) {
                return vehicleScheduleRange.overlaps(driverScheduleRange);
              }
              return false;
            });
        });
      }

      return false;
    });
  }

  yield put({ type: 'FETCH_VEHICLE_SCHEDULES_SUCCEEDED', data, schedulesOverlap: overlaps });
}

function* searchVehicles(action) {
  yield put({ type: 'PLANNER_LOADING', key: 'searchVehicles' });

  const { filterType } = yield select(getVehiclesSearchInfo);
  const {
    filters: { startDate, endDate },
  } = yield select(getPlannerData);

  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    data: { data },
  } = yield call(Api.searchVehicles, {
    token,
    slug,
    partnerJwt,
    ...action.payload,
    ...(filterType === 'all'
      ? {}
      : {
          from: startDate.toISOString(),
          to: endDate.toISOString(),
        }),
  });

  yield put({ type: 'SEARCH_VEHICLES_SUCCEEDED', data: data, vehicleTypeId: action.payload.vehicleTypeId });
}

function* searchWorkers(action) {
  yield put({ type: 'PLANNER_LOADING', key: 'searchWorkers' });

  const {
    token,
    partnerJwt,
    dispatcher_info: {
      data: {
        company: { slug },
      },
    },
  } = yield select(AuthSelectors.getData);
  const {
    data: { data },
  } = yield call(Api.searchWorkers, { token, slug, partnerJwt, ...action.payload });

  yield put({ type: 'SEARCH_WORKERS_SUCCEEDED', data: data });
}

function* assignVehicleToDriver(action) {
  try {
    yield put({ type: 'PLANNER_LOADING', key: 'assignVehicleToDriver' });
    const {
      token,
      partnerJwt,
      dispatcher_info: {
        data: {
          company: { slug },
        },
      },
    } = yield select(AuthSelectors.getData);
    const {
      data: { data },
    } = yield call(Api.assignVehicleToDriver, { token, slug, partnerJwt, ...action.payload });
    yield put({ type: 'ASSIGN_VEHICLE_TO_DRIVER_SUCCEEDED', data: data });
  } catch (e) {
    yield put({ type: 'ASSIGN_VEHICLE_TO_DRIVER_FAILED', e });
  }
}

function* init() {
  try {
    yield put({ type: 'INIT_AUTH' });
    yield put({ type: 'INIT_PLANNER_WORKER' });
    yield put({ type: 'INIT_REGION' });
    yield put({ type: 'INIT_SETTINGS' });
    yield put({ type: 'BUID_WORKER_SEQUENCE_DATA' });
    yield put({ type: 'FETCH_ALL_TEAM_REQUEST' });
    yield put({ type: 'REFRESH_COMPANY_SETTING' });
    yield put({ type: 'FETCH_ITEM_TYPES' });
    yield put({ type: 'FETCH_SERVICE_TYPES' });
    yield put({ type: 'INIT_NETWORK' });
    yield put({ type: 'INIT_PARTNER_WORKER_SOCKET' });
  } catch (error) {
    yield put({ type: 'DISPLAY_MESSAGE', message: error.message, variant: 'error' });
  }
}

function* resetDate({ startDate, endDate, open }) {
  yield put({ type: 'CHANGE_DATE', startDate, endDate });
  if (open === 0) {
    yield initTasks({});
  }
}

function* changeWorkerFilter({ worker, updateTasks = true }) {
  const workerId = worker.id;
  const arraySelectedWorker = yield select(getSelectedWorker);

  if (arraySelectedWorker && arraySelectedWorker.includes(workerId)) {
    yield put({
      type: 'UPDATE_VISIBLE_ROUTES',
      workerId: worker.id,
      visible: false,
      source: 'timeline',
    });
  } else {
    yield put({
      type: 'UPDATE_VISIBLE_ROUTES',
      workerId: worker.id,
      visible: true,
      source: 'timeline',
    });
  }
  yield put({ type: 'APPLY_WORKER_FILTER', workerId, updateTasks });
  const currentOuterFilter = yield select(getCurrentTaskFilter);
  if (currentOuterFilter === AVAILABLE_FILTERS.ASSIGNED) {
    yield put({
      type: 'MANIPULATE_MASTER_FILTER_BY_OUTER_STATUS',
      status: currentOuterFilter,
      previousTaskFilter: currentOuterFilter,
    });
  }
  yield put({ type: 'UPDATE_QUERY_STRING' });
  yield initTasks({});
}

function* refresh({ changeZoom = true, onSuccess, clearCurrentTasks = true } = {}) {
  const rightPanelCommand = yield select(getRightPanelCommand);
  if (rightPanelCommand !== 'collapse') {
    yield initWorkers();
  }

  if (clearCurrentTasks) {
    yield put({ type: 'REFRESH_TASKS' });
  }

  yield initTasks({ changeZoom, onSuccess });
}

function* updateCurrentView() {
  const showList = yield select(getShowList);
  yield put({ type: 'UPDATE_QUERY_STRING' });
  if (showList) {
    yield refresh();
  }
}

function* refreshAll() {
  yield put({ type: 'REFRESH_ALL_INIT' });
  yield initTasks({});
  yield init();
}

function* changeTaskFilter({ taskFilter, isLoadTasks }) {
  const previousTaskFilter = yield select(getStopFilter);
  yield put({ type: 'SET_LOADED_TOTAL', loadedItemTotal: 0, loadedTasksTotal: 0 });
  yield put({ type: 'APPLY_TASK_FILTER', taskFilter, isResetCurrentPresetFilter: true });
  yield put({ type: 'MANIPULATE_MASTER_FILTER_BY_OUTER_STATUS', status: taskFilter, previousTaskFilter });
  yield put({ type: 'UPDATE_QUERY_STRING' });

  if (isLoadTasks) {
    yield initTasks({});
  }
}

function* searchTask({ searchText }) {
  let order_item_ids = [];
  yield put({ type: 'UPDATE_SEARCH_TEXT', searchText });
  try {
    if (searchText) {
      const {
        token,
        partnerJwt,
        dispatcher_info: {
          data: {
            company: { slug },
          },
        },
      } = yield select(AuthSelectors.getData);
      const searchResult = yield call(Api.searchTask, { searchText, token, partnerJwt, slug });
      order_item_ids = searchResult.data && searchResult.data.data;
    }
  } catch (e) {
    console.error(e);
  }
  yield put({ type: 'UPDATE_SEARCH_PARAM', searchText, order_item_ids });
  yield initTasks({});
}

function* selectRegion({ regionId }) {
  yield put({ type: 'REGION_SELECTED', regionId });
  yield refresh({});
}

function* clearZoneFilter({ isLoadTasks }) {
  yield put({ type: 'CLEAR_ZONE_FILTER_SUCCESSFUL' });
  if (isLoadTasks) {
    yield refresh({});
  }
}

function* clearHubFilter({ isLoadTasks }) {
  yield put({ type: 'CLEAR_HUB_FILTER_SUCCESSFUL' });
  if (isLoadTasks) {
    yield refresh({});
  }
}

function* changeOrderItemIdFilter({ payload: { isLoadTasks, orderItemIds } }) {
  yield put({ type: 'CHANGE_ORDER_ITEM_IDS_FILTER_SUCCESSFUL', orderItemIds });
  if (isLoadTasks) {
    yield initTasks({});
  }
}

function* updateSelectedHubs() {
  yield refresh({});
}

function* updateSelectedZones({ selectedZones }) {
  const currentSelectedHubs = yield select(getSelectedHubs);
  const regionData = yield select(getZonesData);
  const regionId = combineHubAndZonesFilter({ selectedHubs: currentSelectedHubs, selectedZones, regionData });
  if (regionId) {
    yield put({ type: 'SELECT_REGION', regionId });
  }
}

const combineHubAndZonesFilter = ({ selectedHubs, selectedZones, regionData }) => {
  let lstRegionName = selectedHubs.map((h) => h.regions).flat();
  if (!lstRegionName || selectedHubs.length > lstRegionName.length) {
    return undefined;
  }

  lstRegionName = Array.from(new Set(lstRegionName));
  const selectedRegionByHub = regionData.filter((r) => lstRegionName.includes(r.tag));
  if (!selectedRegionByHub || lstRegionName.length < selectedRegionByHub.length) {
    return undefined;
  }

  const selectedRegionIdByHub = selectedRegionByHub.map((r) => r.id);
  const selectedZonesID = selectedZones.map((z) => z.id);
  return Array.from(new Set(selectedRegionIdByHub.concat(selectedZonesID)));
};

function* initSocket({ payload }) {
  const showCreateOrderSuccessMessageSetting = yield select(getShowCreateOrderSuccessMessage);
  try {
    setInterval(() => {
      if (Object.keys(ETA_DATA_BATCH).length > 0) {
        store.dispatch({ type: 'TASK_ETA_UPDATE', etaTaskData: ETA_DATA_BATCH });
        ETA_DATA_BATCH = {};
      }
      if (Object.keys(WORKER_DATA_UPDATED_BATCH).length > 0) {
        store.dispatch({
          type: 'UPDATE_WORKERS',
          updatedWorkersMap: WORKER_DATA_UPDATED_BATCH,
        });
        WORKER_DATA_UPDATED_BATCH = {};
      }
    }, 5000);
    const {
      dispatcher_info: {
        data: {
          company: { id: companyId, slug },
        },
      },
      partnerJwt,
      token,
    } = yield select(AuthSelectors.getData);
    const jwt = partnerJwt ? partnerJwt : token;
    if (jwt && companyId) {
      const channelName = payload?.channelName || (partnerJwt ? 'partner' : 'company');
      const channelId = payload?.id || companyId;
      const topic = `${channelName}:${channelId}`;
      const channel = webSocketService
        .getSocket(partnerJwt ? CONNECTION_TYPES.PARTNER : CONNECTION_TYPES.NORMAL, { company_slug: slug, jwt })
        .getChannelByTopic({
          topic,
          onError: ({ reason }) => {
            console.error('tracking test failed join', reason, topic, slug);
          },
          onTimeout: () => {
            console.error('tracking test Networking issue. Still waiting...');
          },
        });

      channel.on('notification', (msg) => {
        if (msg) {
          const { event_type, payload, body } = msg;
          if (event_type === SOCKET_EVENTS.LOCATION_UPDATE && payload && payload.worker) {
            store.dispatch({ type: 'UPDATE_WORKER', updatedWorker: payload });
            MapController.updateWorkersData({
              ...payload.worker,
              location_updated_at: new Date().toISOString(),
            });
            MapController.driverMove(payload.worker);

            store.dispatch({ type: 'UPDATE_WORKER_LOCATION' });
          }
          if (event_type === SOCKET_EVENTS.DROP_OFF_COMPLETED && payload && payload.worker_id) {
            store.dispatch({ type: 'DROP_OFF_COMPLETED', workerId: payload.worker_id });
          }

          if (
            [
              SOCKET_EVENTS.PICKUP_COMPLETED,
              SOCKET_EVENTS.DROP_OFF_COMPLETED,
              SOCKET_EVENTS.ORDER_ITEM_CANCELED,
              SOCKET_EVENTS.UNASSIGN_WORKER,
              SOCKET_EVENTS.WORKER_ACCEPTED_TASK_GROUP,
              SOCKET_EVENTS.WORKER_MARK_AS_FAILED,
              SOCKET_EVENTS.WORKER_REASSIGNED,
              SOCKET_EVENTS.SEQUENCE_UPDATED,
            ].includes(event_type) &&
            payload
          ) {
            const {
              worker_id: workerId,
              ongoing_tasks_count: currentWorkerGoingTaskCount,
              worker_id_and_ongoing_tasks_count_map: workerIdAndOngoingTasksCountMap,
            } = payload;
            const workersNeedUpdate = [];

            if (workerId) {
              workersNeedUpdate.push({
                id: workerId,
                ongoing_tasks_count: currentWorkerGoingTaskCount,
              });
            }

            if (workerIdAndOngoingTasksCountMap) {
              for (const [workerId, onGoingTaskCount] of Object.entries(workerIdAndOngoingTasksCountMap)) {
                workersNeedUpdate.push({
                  id: workerId,
                  ongoing_tasks_count: onGoingTaskCount,
                });
              }
            }

            workersNeedUpdate.forEach((newWorkerData) => {
              store.dispatch({ type: 'UPDATE_WORKER', updatedWorker: { worker: newWorkerData } });
            });

            MapController.updateWorkersOnGoingTasksCount(payload);
          }

          if (event_type === SOCKET_EVENTS.ETA_UPDATE && payload && payload.task_data) {
            const etaTaskData = payload.task_data;
            WORKER_DATA_UPDATED_BATCH[payload.worker_id] = {
              id: payload.worker_id,
              active_worker_sequence_optimised_at: payload.optimised_at,
            };

            etaTaskData.forEach((tk) => {
              ETA_DATA_BATCH[tk.id] = tk;
            });
          }
          if (event_type === SOCKET_EVENTS.ORDER_CREATED) {
            showCreateOrderSuccessMessageSetting &&
              store.dispatch({ type: 'DISPLAY_MESSAGE', message: body, variant: 'success' });
          }
        }
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* toggleWorkerFilterSort({ filter }) {
  if (filter) {
    yield put({ type: 'UPDATE_WORKER_FILTER', filter });
    yield put({ type: 'TOGGLE_TEAM_SELECTION' });
  }
  const { current } = yield select(getWorkerFilter);
  if (filter?.filterType || current !== 'last_seen') {
    yield put({ type: 'INIT_PLANNER_WORKER' });
  }
}

function* updateFiltersBySelectedTasks() {
  yield put({ type: 'TOGGLE_TEAM_SELECTION' });
  const selectedTasksIds = yield select(getSelectedTasks);
  const allCurrentTasksIds = yield select(getTaskIds);
  const selectedTasks =
    selectedTasksIds && selectedTasksIds.length
      ? allCurrentTasksIds.filter((id) => selectedTasksIds.includes(id))
      : allCurrentTasksIds;
  const { distanceType } = yield select(getWorkerFilter);

  const types = [];
  selectedTasks.forEach((task) => {
    if (!types.includes(task.type)) {
      types.push(task.type);
    }
  });

  yield call(toggleWorkerFilterSort, {
    filter: { distanceType: distanceType === 'all' || types.includes(distanceType) ? distanceType : 'all' },
  });
}

function* updateSelectedTasksOnStopSelect({ taskIds, unSelect = false }) {
  const selectedItemsBySG = yield select(getSelectedItemsByStepGroup);
  let selectedTasks = yield select(getSelectedTasks);
  // find tasks with ids
  const data = yield select(getTaskData);
  const ids = yield select(getTaskIds);
  const rightPanelCommand = yield select(getRightPanelCommand);
  const missingInfoFixMode = yield select(getMissingInfoFixMode);

  taskIds.some((id) => {
    if (unSelect && selectedTasks.includes(id)) {
      selectedTasks = selectedTasks.filter((a) => a !== id);
      const t = Object.keys(selectedItemsBySG);
      t.forEach((x) => {
        const values = selectedItemsBySG[x] || [];
        const taskIds = values['taskIds'];
        if (taskIds.includes(id)) {
          delete selectedItemsBySG[x];
        }
      });
    } else if (
      !unSelect &&
      (missingInfoFixMode || !data[id].missing_info) &&
      (['unassigned', 'assigned'].includes(data[id].task_group.state) ||
        'reported' === getTaskStatus(data[id]) ||
        (['invalidated'].includes(data[id].task_group.state) && data[id].transfer_state === 'accepted')) &&
      getTaskStatus(data[id]) !== 'completed'
    ) {
      selectedTasks = [...selectedTasks, id];
      const key = `${data[id].order_item_id}_${data[id].step_group}`;
      selectedItemsBySG[key] = { taskIds: [id] };
    }

    return selectedTasks.length === MAX_AMOUNT_OF_SELECTED_TASKS;
  });
  // Select or unselect workers automatically
  const assignedWorkers = findAssignedWorkers({ ids: ids.filter((id) => selectedTasks.includes(id)), data });
  const newState = {
    selectionMode: selectedTasks.length > 0,
    selectedTasks,
    selectedItemsByStepGroup: selectedItemsBySG,
    selectedAssignedTask: assignedWorkers.length > 0,
  };

  if (selectedTasks.length === 0) {
    newState.selectedWorkers = [];
    yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams: [] });
  }

  if (selectedTasks.length > 0 && rightPanelCommand !== 'expand' && !missingInfoFixMode) {
    yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'expand' });
  }

  yield put({ type: 'SET_SELECT_STOP', newState });
}

function* updateSelectedTasks({ tasks, select: selectTasks }) {
  const selectedItemsByStepGroup = groupBy(tasks, (t) => {
    return `${t.order_item_id}_${t.step_group}`;
  });

  const selectedItemsBySG = {};
  for (const key in selectedItemsByStepGroup) {
    const values = selectedItemsByStepGroup[key];
    const taskIds = values.map((v) => {
      return v.id;
    });
    selectedItemsBySG[key] = { taskIds: taskIds };
  }
  let selectedTasks = yield select(getSelectedTasks);
  let selectedWorkers = yield select(getSelectedWorkers);
  const data = yield select(getTaskData);
  const ids = yield select(getTaskIds);
  const rightPanelCommand = yield select(getRightPanelCommand);
  const missingInfoFixMode = yield select(getMissingInfoFixMode);

  if (selectedTasks.length !== MAX_AMOUNT_OF_SELECTED_TASKS) {
    tasks.some((t) => {
      if (!selectTasks && selectedTasks.includes(t.id)) {
        selectedTasks = selectedTasks.filter((a) => a !== t.id);
      } else if (selectTasks && !selectedTasks.includes(t.id)) {
        if (
          (missingInfoFixMode || !t.missing_info) &&
          (t.task_group.state === 'unassigned' || t.task_group.state === 'assigned')
        ) {
          selectedTasks = [...selectedTasks, t.id];
        }
      }

      return selectedTasks.length === MAX_AMOUNT_OF_SELECTED_TASKS;
    });
  }
  // Select or unselect workers automatically
  const assignedWorkers = findAssignedWorkers({ ids: ids.filter((id) => selectedTasks.includes(id)), data });
  assignedWorkers.forEach((workerId) => {
    if (!selectedWorkers.includes(workerId)) {
      selectedWorkers.push(workerId);
    }
  });

  if (!selectTasks) {
    const unselectWorkers = findAssignedWorkers({ ids, data }).filter((w) => !assignedWorkers.includes(w));
    selectedWorkers = selectedWorkers.filter((w) => !unselectWorkers.includes(w));
  }

  if (selectedTasks.length > 0 && rightPanelCommand !== 'expand' && !missingInfoFixMode) {
    yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'expand' });
  }

  if (selectedTasks.length === 0) {
    selectedWorkers = [];
    yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams: [] });
  }

  yield put({
    type: 'SET_SELECT_TASKS',
    selectionMode: true,
    selectedTasks,
    selectedWorkers,
    selectedAssignedTask: assignedWorkers.length > 0,
    selectedItemsByStepGroup: selectedItemsBySG,
  });
}

function* selectAll() {
  const selectedTasks = yield select(getSelectedTasks);
  const selectedWorkers = yield select(getSelectedWorkers);
  const rightPanelCommand = yield select(getRightPanelCommand);
  const missingInfoFixMode = yield select(getMissingInfoFixMode);

  const data = yield select(getTaskData);
  const ids = yield select(getTaskIds);
  const allSelectedTaskState = selectAllStop({ selectedWorkers, data, ids, missingInfoFixMode });
  if (selectedTasks.length === 0) {
    yield put({
      type: 'SET_SELECT_TASKS',
      ...allSelectedTaskState,
    });
    if (ids.length > 0) {
      yield call(selectAllTasksByFilter);
    }
    if (rightPanelCommand !== 'expand') {
      yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'expand' });
    }
  } else {
    yield put({ type: 'SET_LOADED_TOTAL', loadedItemTotal: 0, loadedTasksTotal: 0 });
    yield put({
      type: 'SET_SELECT_TASKS',
      ...{ selectedTasks: [], selectedItemsByStepGroup: {}, selectedWorkers: [], selectionMode: false },
    });
    yield put({ type: 'UPDATE_SELECTED_TEAM', newSelectedTeams: [] });
    yield put({ type: 'CHECK_FOR_MORE_TASKS', tasks: { data: [] } });
  }
}

const selectAllStop = ({ selectedWorkers, data, ids, missingInfoFixMode }) => {
  const selectedTasks = allAvailableSelectTasks({ data, ids, missingInfoFixMode });
  const assignedWorkers = findAssignedWorkers({ ids: ids.filter((id) => selectedTasks.includes(id)), data });
  assignedWorkers.forEach((workerId) => {
    if (!selectedWorkers.includes(workerId)) {
      selectedWorkers.push(workerId);
    }
  });
  return { selectionMode: true, selectedTasks, selectedWorkers, selectedAssignedTask: assignedWorkers.length > 0 };
};

export default function* sagas() {
  try {
    yield debounce(50, 'INIT_PLANNER', init);
    yield takeLatest('INIT_NETWORK', initNetwork);
    yield takeLatest('INIT_REGION', initRegions);
    yield takeLatest(['INIT_PLANNER_WORKER', 'CREATING_DRIVERS_SUCCESS', 'CAPACITY_CALCULATED_DONE'], initWorkers);
    yield takeLatest('INIT_SETTINGS', initSettings);
    yield takeLatest('INIT_VEHICLE_TYPES', initVehicleTypes);
    yield takeLatest(['INIT_TASKS', 'CLEAR_WORKER_FILTER', 'UPLOAD_ORDER_SUCCEEDED'], initTasks);
    yield takeLatest('INIT_AUTH', initAuth);
    yield takeLatest('INIT_SOCKET', initSocket);
    yield debounce(1000, 'LOAD_MORE_TASK', loadMoreTask);
    yield takeLatest('RESET_DATE', resetDate);
    yield takeLatest('CHANGE_WORKER_FILTER', changeWorkerFilter);
    yield takeLatest('UPDATE_CURRENT_VIEW', updateCurrentView);
    yield takeLatest('REFRESH', refresh);
    yield takeLatest('REFRESH_ALL', refreshAll);
    yield takeLatest('CHANGE_TASK_FILTER', changeTaskFilter);
    yield takeLatest('SELECT_REGION', selectRegion);
    yield takeLatest('CLEAR_ZONE_FILTER', clearZoneFilter);
    yield takeLatest('RESET_SETTINGS', initSettings);
    yield debounce(1000, 'SEARCH_TASK_REQUEST', searchTask);
    yield takeLatest('UPDATE_SELECTED_HUBS', updateSelectedHubs);
    yield takeLatest('CLEAR_HUB_FILTER', clearHubFilter);
    yield takeLatest('UPDATE_SELECTED_ZONES', updateSelectedZones);
    yield takeLatest('REFRESH_COMPANY_SETTING', refreshCompanySetting);
    yield takeLatest('CHANGE_ORDER_ITEM_IDS_FILTER', changeOrderItemIdFilter);
    yield takeLatest('REQUEST_GET_WORKER', getWorker);
    yield takeLatest('REQUEST_GET_VEHICLES', getVehicles);
    yield takeLatest('REQUEST_GET_VEHICLE_SCHEDULES', getVehicleSchedules);
    yield debounce(500, 'REQUEST_SEARCH_VEHICLES', searchVehicles);
    yield takeLatest('REQUEST_ASSIGN_VEHICLE_TO_DRIVER', assignVehicleToDriver);
    yield takeLatest('REQUEST_SEARCH_WORKERS', searchWorkers);
    yield debounce(500, 'UPDATE_VEHICLES_SEARCH_TERM', getVehicles);
    yield takeLatest('TOGGLE_WORKER_FILTER_SORT', toggleWorkerFilterSort);
    yield takeLatest('SELECT_TASKS', updateSelectedTasks);
    yield takeLatest('SELECT_STOP', updateSelectedTasksOnStopSelect);
    yield takeLatest('TOGGLE_SELECT_ALL_STOPS', selectAll);
    yield takeLatest(
      [
        'TOGGLE_SELECTION',
        'SELECT_STOP',
        'SELECT_TASK_BY_MAP_SELECTION',
        'SELECT_TASKS',
        'DESELECT_ALL_STOPS',
        'SELECT_ALL_STOPS',
        'SELECT_BOUNDS',
      ],
      updateFiltersBySelectedTasks
    );
  } catch (err) {
    yield put({ type: 'DISPLAY_MESSAGE', message: err.message, variant: 'error' });
  }
}
