import Axios from 'axios';

import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { getParamsFromAttachedTo } from './helpers/AssignHelper';

export class DocumentsService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getPreSignedUrl = (filename) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/documents/presigned_url`), { filename })
      .then((response) => {
        const result = this.extractData(response);
        return result?.['presigned_url'];
      })
      .catch((error) => this.handleError(error));
  };

  getDocumentCodes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/document_classifications`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createDocument = (document) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/documents`), this.transformToSaveDocumentBody(document))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateDocument = (document) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/documents/${document.id}`), this.transformToSaveDocumentBody(document))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteDocument = (document) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/documents/${document.id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getDocuments = ({ page = 1, page_size = 10, order_number, ...restData }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/documents`), {
        page,
        page_size,
        order_number,
        ...restData,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getDuplicateDocuments = ({ order_number, page_size = 1000, file_names = [] }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/documents`), { order_number, page_size, file_names })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getDocumentLogs = ({ id, page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/documents/${id}/logs`), {
        page,
        page_size,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  uploadDocumentToS3 = (preSignedUrl, file) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Axios.put(preSignedUrl, file, { headers })
      .then((response) => response)
      .catch((error) => {
        this.handleError(error);
      });
  };

  transformToSaveDocumentBody = (document) => {
    const body = {
      name: document.name,
      privacy: document.privacy,
      classification_code: document.code.value === 'custom' ? document.customCode : document.code.value,
    };

    if (document.url) {
      body.document_url = document.url;
    }

    const { item_ids, order_number } = getParamsFromAttachedTo(document.attachedTo);
    body.item_ids = item_ids;
    body.order_number = order_number;

    return body;
  };
}

export const documentsService = new DocumentsService({ authService });
