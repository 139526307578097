export const MAX_LENGTH = 300;

export const CONST_VARIABLE = {
  NAME: 'NAME',
  COMPANY: 'COMPANY',
  DESCRIPTION: 'DESCRIPTION',
  PERMISSION: 'PERMISSION',
};

export const PERMISSION_LIST = {
  explore: 'explore',
  dashboard: 'dashboard',
  items: 'items',
  orders: 'orders',
  partners: 'partners',
  reports: 'reports',
  network: 'network',
  invoices: 'invoices',
  manage: 'manage',
};

export const READ_PERMISSION_LIST = [
  'dispatcher.orders.read',
  'dispatcher.partners.read',
  'dispatcher.reports.read',
  'dispatcher.manage.read',
  'dispatcher.items.read',
  'dispatcher.dashboard.read',
  'dispatcher.explore.read',
  'dispatcher.network.read',
  'dispatcher.invoices.read',
];

export const WRITE_PERMISSION_LIST = [
  'dispatcher.orders.write',
  'dispatcher.partners.write',
  'dispatcher.reports.write',
  'dispatcher.network.write',
  'dispatcher.items.write',
  'dispatcher.dashboard.write',
  'dispatcher.explore.write',
  'dispatcher.manage.write',
  'dispatcher.invoices.write',
];

export const MUST_HAVE_READ_PERMISSION_LIST = ['dispatcher.explore.read'];
