import { cloneDeep } from 'lodash-es';

import { isString } from '@yojee/auth/utils/AuthUtils';
import { getItemStepUniqueKey } from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/utils';

import actionTypes from './actionTypes';
import { fromOrderDataToOrderFormModels } from './helpers';

export const reset = () => {
  return { type: actionTypes.reset() };
};

export const updateState = (newState) => {
  return { type: actionTypes.updateState(), newState };
};

export const recalculateStepsTimeBaseOnTemplate = () => {
  return { type: actionTypes.recalculateStepsTimeBaseOnTemplate() };
};

export const updateOrderDetailState = (orderDetailData, dispatch) => {
  const orderDetail = {
    ...orderDetailData,
    originalOrderFormModels: orderDetailData.data ? fromOrderDataToOrderFormModels(orderDetailData.data) : undefined,
  };
  const orderFormModels = cloneDeep(orderDetail.originalOrderFormModels);
  const setOrderDataAction = {
    type: actionTypes.updateState(),
    newState: { orderDetail, orderFormModels, formKeysMetaDataMap: {} },
  };

  dispatch(setOrderDataAction);
  dispatch(recalculateStepsTimeBaseOnTemplate());
};

export const updateCreateOrderState = (createOrderData) => {
  if (createOrderData.status === 'failed') {
    return {
      type: actionTypes.updateStateInCaseCreateOrderFailed(),
      createOrderData,
    };
  } else if (createOrderData.status === 'success') {
    return {
      type: actionTypes.updateState(),
      newState: { createOrder: createOrderData },
    };
  }

  return { type: actionTypes.updateState(), newState: { createOrder: createOrderData } };
};

export const updateEditOrderState = (editOrderData) => {
  if (editOrderData.status === 'failed') {
    return {
      type: actionTypes.updateStateInCaseUpdateOrderFailed(),
      editOrderData,
    };
  } else if (editOrderData.status === 'success') {
    return {
      type: actionTypes.updateState(),
      newState: { editOrder: editOrderData, successMessage: 'Order updated' },
    };
  }

  return { type: actionTypes.updateState(), newState: { editOrder: editOrderData } };
};

export const updateReapplyOperationsEditOrderState = (bulkReapplyOperationsResp) => {
  if (bulkReapplyOperationsResp.status === 'failed') {
    return { type: actionTypes.updateState(), newState: { bulkReapplyOperationsResp } };
  } else if (bulkReapplyOperationsResp.status === 'success') {
    const {
      data: { data: bulkOperationsRespData },
    } = bulkReapplyOperationsResp;

    const failedOrderIds = bulkOperationsRespData.reduce((acc, curr) => {
      if (curr.status === 'error') {
        acc.push(curr.errors[0].metadata.order_id);
      }
      return acc;
    }, []);

    if (failedOrderIds.length === 0) {
      return {
        type: actionTypes.updateState(),
        newState: { successMessage: 'Reapplied operations for order' },
      };
    } else {
      const errorMessage = 'Reapply operations failed for order ids: ' + failedOrderIds.join(', ');
      return { type: actionTypes.updateState(), newState: { errorMessage } };
    }
  }
};

export const updateSchemaForOrderFieldTemplate = ({ templateDetail }) => {
  if (templateDetail.status === 'failed') {
    let errorMessage = templateDetail?.error?.message;
    if (!isString(errorMessage)) {
      errorMessage = 'Internal server error. Please try again';
    }
    return { type: actionTypes.updateState(), newState: { errorMessage } };
  } else if (templateDetail.status === 'success') {
    return {
      type: actionTypes.updateState(),
      newState: {
        templateDetail: {
          ...templateDetail,
          data: {
            ...templateDetail?.data,
            fields_schema: {
              ...templateDetail?.data?.fields_schema,
              ...(templateDetail?.data?.fields_schema?.order_step_from_time
                ? {
                    order_step_from_time: {
                      ...templateDetail?.data?.fields_schema?.order_step_from_time,
                      required: false,
                      system_required: false,
                    },
                  }
                : {}),
              ...(templateDetail?.data?.fields_schema?.order_step_to_time
                ? {
                    order_step_to_time: {
                      ...templateDetail?.data?.fields_schema?.order_step_to_time,
                      required: false,
                      system_required: false,
                    },
                  }
                : {}),
            },
          },
        },
      },
    };
  }

  return { type: actionTypes.updateState(), newState: { templateDetail } };
};

export const updateTasksState = (tasksData) => {
  const { hits } = tasksData?.data?.data?.data || {};
  const { hits: results } = hits || {};
  const tasks = results?.map((r) => r._source);

  const itemIdTasksMap = tasks?.reduce((acc, task) => {
    acc[task.order_item_id] = [...(acc[task.order_item_id] || []), task];
    return acc;
  }, {});

  const itemStepKeyTaskMap = tasks?.reduce((acc, task) => {
    const itemStepKey = getItemStepUniqueKey(task);
    acc[itemStepKey] = task;
    return acc;
  }, {});

  return {
    type: actionTypes.updateState(),
    newState: {
      tasks: {
        ...tasksData,
        data: {
          tasks,
          itemStepKeyTaskMap,
          itemIdTasksMap,
        },
      },
    },
  };
};

export const setTemplateDetail = (templateDetailData) => {
  return { type: actionTypes.updateState(), newState: { templateDetail: { data: templateDetailData } } };
};

export const reCalculateCharge = (chargeData) => {
  return {
    type: actionTypes.reCalculateCharge(),
    chargeData,
  };
};

export const addSuccessMessage = (successMessage) => {
  return { type: actionTypes.addSuccessMessage(), successMessage };
};

export const addErrorMessage = (errorMessage) => {
  return { type: actionTypes.addErrorMessage(), errorMessage };
};

export const clearMessage = () => {
  return { type: actionTypes.clearMessage() };
};

export const registerForm = (formKeyPath, formRef) => {
  return { type: actionTypes.registerForm(), formKeyPath, formRef };
};

export const syncFormModel = (formKeyPath, formModel) => {
  return { type: actionTypes.syncFormModel(), formKeyPath, formModel };
};

export const deleteFormModels = (formKeyPath) => {
  return { type: actionTypes.deleteFormModels(), formKeyPath };
};

export const deleteContainerData = (formKeyPath) => {
  return { type: actionTypes.deleteContainerData(), formKeyPath };
};

export const updateFormMetaData = (formKeyPath, formMetaData) => {
  return { type: actionTypes.updateFormMetaData(), formKeyPath, formMetaData };
};

export const searchAddress = ({
  searchKey,
  includeAddressBook = true,
  operationCountry = null,
  allowToUsePlaceSearchApi = true,
  senderLinkType = null,
  senderId = null,
}) => {
  return {
    type: actionTypes.searchAddress(),
    searchKey,
    includeAddressBook,
    operationCountry,
    allowToUsePlaceSearchApi,
    senderLinkType,
    senderId,
  };
};

export const createOrderFieldTemplate = (formData, templateId, senderId) => {
  return { type: actionTypes.createOrderFieldTemplate().start(), formData, templateId, senderId };
};

export const updateOrderFieldTemplate = (formData, templateId, senderId) => {
  return { type: actionTypes.updateOrderFieldTemplate().start(), formData, templateId, senderId };
};

export const deleteOrderFieldTemplate = (senderId, templateId) => {
  return { type: actionTypes.deleteOrderFieldTemplate().start(), senderId, templateId };
};

export const getOrderFieldTemplate = (senderId) => {
  return { type: actionTypes.getOrderFieldTemplate().start(), senderId };
};

export const setOrderFieldTemplateSender = (sender) => {
  return { type: actionTypes.setOrderFieldTemplateSender(), sender };
};

export const selectServiceType = (serviceType) => {
  return { type: actionTypes.selectServiceType(), serviceType };
};

export const OrderBookingActions = {
  getOrderFieldTemplate,
};

export const clearDateTimeInputStart = (formKeyPath) => {
  return { type: actionTypes.clearDateTimeInputStart(), formKeyPath };
};

export const clearDateTimeInputEnd = (formKeyPath) => {
  return { type: actionTypes.clearDateTimeInputEnd(), formKeyPath };
};

export const duplicateOrder = ({ orderFormModels, template, duplicationSpec }) => {
  return {
    type: actionTypes.duplicateOrder(),
    orderFormModels,
    template,
    duplicationSpec,
  };
};
