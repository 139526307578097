import './table.scss';

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

import EmptyZone from '@yojee/ui/base/corners/EmptyZone';

import { TableSkeleton } from '../../furnitures/SkeletonLoading';
import { TableRow as YoJeeTableRow } from './Components';

let lastScrollLeft = 0;

const setClassHelper = (calledClass, position, value) => {
  if (!calledClass || !position || !value) {
    return;
  }
  document.querySelectorAll(calledClass).forEach(function (actions) {
    actions.classList.remove(position + '-sticky-block');
    actions.classList.remove(position + '-sticky-none');
    actions.classList.add(position + '-sticky-' + value);
  });
};
const trackHorizontalScrolling = (tableElement) => (e) => {
  const { scrollWidth = 0, scrollLeft = 0, offsetWidth = 0, id } = tableElement ?? {};
  const scrollBarWidth = tableElement?.offsetWidth - tableElement?.clientWidth;

  // horizotal scrolling
  if (lastScrollLeft !== scrollLeft || e?.type === 'resize' || e?.type === 'init') {
    const scrollRight = scrollWidth - offsetWidth - scrollLeft;
    const leftClass = '.left-sticky-' + id;
    const rightClass = '.right-sticky-' + id;

    if (scrollLeft <= 0) {
      setClassHelper(leftClass, 'left', 'none');
    } else {
      setClassHelper(leftClass, 'left', 'block');
    }
    if (scrollRight <= 0) {
      setClassHelper(rightClass, 'right', 'none');
    } else {
      setClassHelper(rightClass, 'right', 'block');
    }

    lastScrollLeft = scrollLeft;
    document.querySelectorAll('.actions-' + id).forEach(function (actions) {
      actions.style.right = `${scrollRight + scrollBarWidth}px`;
    });
  }
};

const useStyles = makeStyles((theme) => ({
  cellCheckbox: {
    width: theme.spacing(5),
    padding: `0 ${theme.spacing(1)}px`,
    zIndex: 3,
  },
  checkbox: {
    '& input[type="checkbox"]': {
      height: '100%',
      width: '100%',
    },
  },
  paper: {
    borderRadius: 0,
    borderTopRightRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
  },
  tableContainer: {
    borderRadius: 0,
    borderTopRightRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    maxHeight: '100vh',
    maxWidth: '100%',
  },
  table: {
    minWidth: theme.spacing(112.5),
  },
  tableFixedLayout: {
    tableLayout: 'auto',
    width: '100%',
  },
  tableHead: {
    height: '44px',
    '& th': {
      padding: `0 ${theme.spacing(2)}px`,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      borderBottomColor: theme.palette.black?.B4,
      backgroundColor: ({ activeColor }) => (activeColor === 'fileHover' ? 'rgba(0,0,0,0.1)' : '#fafafa'),
    },
  },
}));

const YoJeeTable = ({
  id,
  isLoading,
  data,
  columns,
  colWidths,
  showCheckBox,
  disabledCheckBox,
  showSorter,
  order,
  orderBy,
  rowCount,
  onSelectAllClick,
  numSelected,
  onRequestSort,
  isSelected,
  onRowClick,
  rowAlwaysClickable,
  rowActions,
  orderByColumnIds,
  activeColor,
  emptyDataMessage,
  className,
  dataCyTable,
  dataCyRow,
  dataCyCheckbox,
  rowClassName,
}) => {
  const classes = useStyles({ activeColor });

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    const tableElement = document.getElementById(id);
    if (tableElement) {
      trackHorizontalScrolling(tableElement)({ type: 'init' });
      tableElement.addEventListener('scroll', trackHorizontalScrolling(tableElement));
      window.addEventListener('resize', trackHorizontalScrolling(tableElement));
      return () => {
        tableElement.removeEventListener('scroll', trackHorizontalScrolling(tableElement));
        window.removeEventListener('resize', trackHorizontalScrolling(tableElement));
      };
    }
  }, [id]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Paper className={clsx(classes.paper, className)} variant="outlined">
      <TableContainer id={id} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={clsx(classes.table, { [!!colWidths]: classes.tableFixedLayout })}
          data-cy={dataCyTable}
        >
          <TableHead className={classes.tableHead} data-cy="table-header">
            <TableRow>
              {showCheckBox && (
                <TableCell
                  className={`word-break ${classes.cellCheckbox} left-sticky-` + id}
                  component="th"
                  scope="row"
                >
                  <Checkbox
                    className={classes.checkbox}
                    indeterminate={
                      numSelected > 0 &&
                      (disabledCheckBox.length > 0
                        ? numSelected < rowCount - disabledCheckBox.length
                        : numSelected < rowCount)
                    }
                    checked={
                      rowCount > 0 &&
                      (disabledCheckBox.length > 0
                        ? numSelected > 0 && numSelected === rowCount - disabledCheckBox.length
                        : numSelected === rowCount)
                    }
                    onChange={onSelectAllClick}
                    color="primary"
                    size="small"
                    inputProps={{
                      'aria-label': 'check all',
                    }}
                    style={{
                      ...(colWidths ? { width: colWidths[0] } : {}),
                    }}
                  />
                </TableCell>
              )}
              {columns.map(({ id: columnId, label, ...restProps }, index) => (
                <TableCell
                  className={`word-break ${
                    index === columns.length - 1 && rowActions?.length > 0 ? 'right-sticky-' + id : ''
                  }`}
                  key={columnId}
                  sortDirection={orderBy === columnId ? order : false}
                  style={{
                    ...(colWidths ? { width: colWidths[index + 1] } : {}),
                    whiteSpace: restProps?.isOneLine ? 'nowrap' : 'normal',
                    ...restProps.headerStyle,
                  }}
                  {...restProps}
                >
                  {showSorter && orderByColumnIds.some((id) => columnId === id) ? (
                    <TableSortLabel
                      active={orderBy === columnId}
                      direction={orderBy === columnId ? order : 'asc'}
                      onClick={createSortHandler(columnId)}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-cy="table-body">
            {data &&
              data.map((row, index) => (
                <YoJeeTableRow
                  dataCyRow={dataCyRow}
                  dataCyCheckbox={dataCyCheckbox}
                  key={row.id ?? index}
                  isSelected={isSelected}
                  columns={columns}
                  row={row}
                  index={index}
                  id={id}
                  onRowClick={onRowClick}
                  alwaysClickable={rowAlwaysClickable}
                  showCheckBox={showCheckBox}
                  disabledCheckBox={disabledCheckBox.includes(index)}
                  rowActions={rowActions}
                  activecolor={activeColor}
                  colWidths={colWidths}
                  rowClassName={rowClassName}
                />
              ))}
          </TableBody>
        </Table>
        {data.length === 0 && <EmptyZone message={emptyDataMessage} />}
      </TableContainer>
    </Paper>
  );
};

YoJeeTable.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  colWidths: PropTypes.array,
  data: PropTypes.array,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  showCheckBox: PropTypes.bool,
  disabledCheckBox: PropTypes.array,
  showSorter: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  isSelected: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onRequestSort: PropTypes.func,
  onRowClick: PropTypes.func,
  rowAlwaysClickable: PropTypes.bool,
  onRowEdit: PropTypes.func,
  onRowDelete: PropTypes.func,
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
  orderByColumnIds: PropTypes.array,
  activeColor: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info', 'fileHover']),
  className: PropTypes.string,
  rowClassName: PropTypes.string,
};

YoJeeTable.defaultProps = {
  columns: [],
  data: [],
  numSelected: 0,
  rowCount: 0,
  showCheckBox: false,
  isLoading: false,
  disabledCheckBox: [],
  showSorter: false,
  order: 'asc',
  orderBy: 'name',
  isSelected: () => {},
  onSelectAllClick: () => {},
  onRequestSort: () => {},
  onRowClick: () => {},
  rowAlwaysClickable: false,
  rowActions: [],
  orderByColumnIds: ['name'],
  activeColor: 'default',
  emptyDataMessage: <Trans>No data is available in this table</Trans>,
  className: '',
  rowClassName: undefined,
  dataCyTable: 'yojee-table',
  dataCyRow: 'yojee-table-row',
  dataCyCheckbox: 'yojee-table-checkbox',
};

export default YoJeeTable;
