import { all, fork, select, takeEvery } from 'redux-saga/effects';

import getEnv from '@yojee/helpers/env/getEnv';
import { AddressAutoCompleteSagas } from '@yojee/ui/address-autocomplete/sagas/saga';
import addressPickerSagas from '@yojee/ui/address-picker/saga/saga';
import broadcast from '@yojee/ui/broadcast-task-dialog/saga/saga';
import bulk from '@yojee/ui/BulkUpdate/redux/bulkSaga';
import completeTask from '@yojee/ui/complete-task/saga/saga';
import { DocumentPortalSagas } from '@yojee/ui/documentPortal/saga/saga';
import featureManagementSagas from '@yojee/ui/feature-management/saga/saga';
import { InvitePartnerDialogSagas } from '@yojee/ui/invitePartnerDialog/sagas/saga';
import main from '@yojee/ui/main/saga/saga';
import { MapSaga as map } from '@yojee/ui/map';
import masterFilter from '@yojee/ui/master-filter/saga/saga';
import { InvoicesSagas } from '@yojee/ui/new-order-booking/saga/invoices/saga';
import { OrderBookingSagas } from '@yojee/ui/new-order-booking/saga/saga';
import notificationsSaga from '@yojee/ui/notifications/saga/saga';
import { UIAuthSagas } from '@yojee/ui/onboarding/sagas/auth/saga';
import { UIUserManagementSagas } from '@yojee/ui/onboarding/sagas/userManagement/saga';
import { PartnerManagementSagas } from '@yojee/ui/partner/saga/saga';
import paymentsSaga from '@yojee/ui/pay/saga/saga';
import reportTask from '@yojee/ui/report-task/saga/saga';
import routePlanningTimelineSaga from '@yojee/ui/route-planning-timeline/saga/saga';
import tasksSaga from '@yojee/ui/tasks/saga';

import addressBookAutoComplete from './addressBookAutocomplete/saga';
import addressTagAutoComplete from './addressTagAutoComplete/saga';
import assignment from './assign/saga';
import batch from './batch/saga';
import billingJob from './billingJob/saga';
import chat from './chat/saga';
import company from './company/saga';
import driverAutoComplete from './driverAutoComplete/saga';
import driverTagAutoComplete from './driverTagAutoComplete/saga';
import IdAutoComplete from './IdAutoComplete/saga';
import invoices from './invoices/saga';
import itemsTable from './itemsTable/saga';
import itemTypes from './itemTypes/saga';
import masterSearchAutoComplete from './masterSearchAutoComplete/saga';
import network from './network/saga';
import onboarding from './onboarding/saga';
import optimisation from './optimise/saga';
import orders from './orders/saga';
import partnerAutoComplete from './partnerAutoComplete/saga';
import partnerList from './partnerList/saga';
import partnerSignup from './partnerSignup/saga';
import partnerWorker from './partnerWorker/saga';
import planner from './planner/saga';
import querryString from './queryString/saga';
import routePlanner from './routePlanner/saga';
import scanner from './scanner/saga';
import sendersAutoComplete from './senderAutoComplete/saga';
import sequence from './sequence/saga';
import serviceTypes from './serviceTypes/saga';
import stopList from './stopList/saga';
import taskFilter from './taskFilter/saga';
import tasks from './tasks/saga';
import teams from './team/saga';
import transferTasks from './transferTasks/saga';
import withdrawTasks from './withdrawTasks/saga';
import worker from './worker/saga';

function* watchAndLog() {
  if (getEnv('REACT_APP_IS_LOCAL') === 'true' && getEnv('REACT_OUTPUT_STATE_CHANGES')) {
    yield takeEvery('*', function* logger(action) {
      const state = yield select();

      console.log('action', action);
      console.log('state after', state);
    });
  }
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(planner),
    fork(map),
    fork(optimisation),
    fork(assignment),
    fork(sequence),
    fork(teams),
    fork(main),
    fork(tasks),
    fork(batch),
    fork(worker),
    fork(scanner),
    fork(company),
    fork(onboarding),
    fork(completeTask),
    fork(reportTask),
    fork(taskFilter),
    fork(chat),
    fork(network),
    fork(orders),
    fork(invoices),
    fork(billingJob),
    fork(stopList),
    fork(itemsTable),
    fork(serviceTypes),
    fork(addressBookAutoComplete),
    fork(sendersAutoComplete),
    fork(driverAutoComplete),
    fork(partnerAutoComplete),
    fork(addressTagAutoComplete),
    fork(driverTagAutoComplete),
    fork(itemTypes),
    fork(masterFilter),
    fork(masterSearchAutoComplete),
    fork(IdAutoComplete),
    fork(querryString),
    fork(broadcast),
    fork(partnerList),
    fork(routePlanner),
    fork(transferTasks),
    fork(withdrawTasks),
    fork(UIAuthSagas),
    fork(UIUserManagementSagas),
    fork(PartnerManagementSagas),
    fork(InvitePartnerDialogSagas),
    fork(partnerSignup),
    fork(routePlanningTimelineSaga),
    fork(tasksSaga),
    fork(OrderBookingSagas),
    fork(AddressAutoCompleteSagas),
    fork(DocumentPortalSagas),
    fork(InvoicesSagas),
    fork(addressPickerSagas),
    fork(notificationsSaga),
    fork(paymentsSaga),
    fork(featureManagementSagas),
    fork(bulk),
    fork(partnerWorker),
  ]);
}
